<template>
  <v-row>
    <v-col cols="12" md="12" sm="12">
      <div>
        <v-layout row wrap>
          <v-flex v-for="(item, i) in pettyCashStats" :key="i" s3 sm3 md3 xl2>
            <v-hover v-slot="{ hover }" open-delay="200">
              <v-card
                flat
                style="background-color: #ffffff"
                :elevation="hover ? 16 : 0"
                :class="{ 'on-hover': hover }"
                class="mx-2 mt-3"
                @click="filterList(item.name)"
              >
                <v-list>
                  <v-list-item class="stats">
                    <v-list-item-content>
                      <v-list-item-title class="text-lg-h5 stats-info">{{
                        item.count
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="stats-info">{{
                        item.name
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-avatar :color="item.avatarColor" size="53">
                        <v-icon :color="item.iconColor">{{ item.icon }}</v-icon>
                      </v-avatar>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-hover>
          </v-flex>
        </v-layout>
      </div>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "PettyCashStats",
  data: function () {
    return {};
  },
  computed: {
    pettyCashStats: function () {
      const Statistics =
        this.$store.getters["pettycash/pettycashstate"]("pettycash");

      return [
        {
          count: Statistics ? Statistics.length : [],
          name: "All Requests",
          avatarColor: "teal accent-2",
          iconColor: "teal darken-4",
          icon: "payments",
          background: "coral",
        },
        {
          count: Statistics
            ? Statistics.filter((i) => i.status === "Open").length
            : [],
          name: "Open",
          avatarColor: "orange accent-1",
          iconColor: "orange darken-4",
          icon: "open_in_new",
        },
        {
          count: Statistics
            ? Statistics.filter((i) => i.status === "Pending Approval").length
            : [],
          name: "Pending Approval",
          avatarColor: "light-blue lighten-4",
          iconColor: "light-blue darken-4",
          icon: "pending",
        },
        {
          count: Statistics
            ? Statistics.filter((i) => i.status === "Released").length
            : [],
          name: "Approved",
          avatarColor: "green accent-2",
          iconColor: "green darken-4",
          icon: "fact_check",
        },
      ];
    },

    Stats() {
      return this.$store.getters["Requisition/reqGetters"]("requisitions");
    },
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("pettycash/getPettyCash");
    });
  },
  methods: {
    filterList(item) {
      this.$emit("clicked", item);
    },
  },
};
</script>

<style>
.stats {
  border-radius: 35px;
}
</style>
