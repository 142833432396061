import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: "vuex",
  storage: window.localStorage,
  modules: ["auth"],
});

export default new Vuex.Store({
  modules: {},
  state: {},
  mutations: {},
  getters: {},
  actions: {},
  plugins: [vuexLocalStorage.plugin],
});
