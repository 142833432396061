import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","width":"50vw"},model:{value:(_vm.setDialog),callback:function ($$v) {_vm.setDialog=$$v},expression:"setDialog"}},[_c(VCard,[_c(VList,[_vm._l((_vm.cashComments),function(cashComment,i){return [_c(VListItem,{key:cashComment.entryNo},[_c(VListItemContent,[_c(VListItemSubtitle,[_vm._v(" Comment ")]),_c('div',{staticClass:"text-body-1 font-weight-bold"},[_vm._v(" "+_vm._s(cashComment.comment)+" ")])],1)],1),_c(VDivider,{key:i + 0.01})]})],2),_c(VCardActions,[_c(VBtn,{attrs:{"color":"primary","block":""},on:{"click":function($event){_vm.setDialog = false}}},[_vm._v(" close cash comments ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }