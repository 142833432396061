<template>
  <main>
    <v-row class="fill-height">
      <v-col>
        <v-sheet height="64">
          <v-toolbar flat>
            <v-btn
              outlined
              class="mr-4"
              color="grey darken-2"
              @click="setToday"
            >
              Today
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small> mdi-chevron-right </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Day</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Week</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = '4day'">
                  <v-list-item-title>4 days</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="700">
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="
              listFilterValue.length !== 0 ? applicationsFilter : applications
            "
            @click:more="viewDay"
            @click:date="viewDay"
            :event-color="getEventColor"
            :type="type"
            @click:event="showApplication"
            :show-interval-label="() => false"
            :short-intervals="true"
            interval-height="0"
            interval-width="0"
            :start="
              listFilterValue.length !== 0
                ? applicationsFilter[0] !== undefined
                  ? applicationsFilter[0].start
                  : today
                : applications[0] !== undefined
                ? applications[0].start
                : today
            "
            :event-height="isApprover ? 70 : 60"
            class="leaveCalender"
          >
            <template v-slot:event="{ event }">
              <v-list-item>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-avatar
                      color="blue lighten-4"
                      style="color: rgb(0, 68, 139)"
                      size="40"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ event.leaveData.employeeInitials }}
                    </v-list-item-avatar>
                  </template>
                  <span v-if="event.leaveData.made_by !== undefined">{{
                    event.leaveData.fullName
                  }}</span>
                </v-tooltip>
                <v-list-item-content>
                  <v-list-item-title :style="{ color: event.textColor }">
                    {{ event.leaveType }}
                  </v-list-item-title>
                  <v-list-item-subtitle :style="{ color: event.textColor }">
                    <span class="mr-3">{{ event.name }}</span>
                    <span>
                      {{ event.leaveData.days_applied + " Days" }}
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-chip label :color="getColor(event.status).color" small>
                    {{ event.status }}
                  </v-chip>
                  <v-chip
                    class="mt-1"
                    label
                    :color="getColor(event.status).color"
                    v-if="event.approvalEntries.length > 0 && isApprover"
                    small
                  >
                    {{
                      event.status === "Released" || event.status === "Posted"
                        ? "Approved"
                        : "Approval Entry Open"
                    }}
                  </v-chip>
                </v-list-item-action>
              </v-list-item>
            </template>
            <template v-slot:day-header="{ weekday }">
              <v-sheet
                title="this is a holiday"
                width="100%"
                height="100%"
                tile
                class="daySheet"
                v-if="weekday === 0 || weekday === 6"
              ></v-sheet>
            </template>
          </v-calendar>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
          </v-menu>
        </v-sheet>
      </v-col>
    </v-row>
  </main>
</template>
<style>
@import "../styles.css";
</style>
<script>
import { leaveMixin, SetupMixin } from "../../../mixins";
import LeaveMethods from "../LeaveMethods";
/* eslint-disable camelcase */
export default {
  name: "calender",
  components: {},
  props: { isApprover: Boolean },
  mixins: [leaveMixin, SetupMixin],
  data: () => ({
    focus: "",
    today: new Date().toISOString().substr(0, 10),
    type: "week",
    typeToLabel: {
      week: "Week",
      day: "Day",
      "4day": "4 Days",
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    message: [],
    messageType: "",
  }),
  watch: {
    listFilterValue() {
      if (this.listFilterValue.length !== 0) {
        this.applications = this.applications.filter((app) => {
          let bool = false;
          Object.keys(app).forEach((key, index) => {
            if (
              app[key].toString().toLowerCase().indexOf(this.listFilterValue) >
              -1
            ) {
              bool = true;
            }
          });
          return bool;
        });
      }
    },
  },
  created() {},
  mounted() {
    this.$refs.calendar.checkChange();
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.getSetup();
    });
  },
  computed: {
    isCalender: function () {
      return this.$store.getters["leave/leaveGetter"]("isCalender");
    },
    applications: {
      get() {
        return this.getApplications();
      },
      set(value) {
        const updateRange = [];
        value.map((app) => {
          updateRange.push(app.leaveData);
        });
        this.getApplications();
      },
    },
  },
  methods: {
    ...LeaveMethods,
  },
};
</script>
