<template>
  <v-container fluid>
    <router-view />
  </v-container>
</template>
<script>
export default {
  name: "Approval",
  components: {},
};
</script>
