import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,[_vm._v(" Attachments ")]),_c('div',{staticClass:"d-flex flex-column"},[(_vm.attachmentError)?_c(VAlert,{attrs:{"type":"error","dense":"","text":"","icon":"mdi-cloud-alert","border":"left"}},[_vm._v(" Each attachment should be less than 2MB and not more than 5 attachments ")]):_vm._e(),_c(VCol,{staticClass:"d-flex",staticStyle:{"flex-direction":"row"},attrs:{"cols":"12"}},[(_vm.formData.attachments.length !== 0)?_c('div',{staticStyle:{"width":"50%"},attrs:{"flex-grow-0":""}},[_c(VList,_vm._l((_vm.formData.attachments),function(file,i){return _c(VListItem,{key:i},[_c(VListItemIcon,[_c(VChip,{attrs:{"label":"","color":"blue lighten-4","small":""}},[_c(VIcon,{attrs:{"small":"","color":"blue"}},[_vm._v("mdi-file-outline")])],1)],1),_c(VListItemContent,[_c(VListItemTitle,[_c(VCard,{attrs:{"max-width":"1000"}},[_vm._v(" "+_vm._s(file.fileName)+" ")])],1)],1),_c(VListItemIcon,[(
                  !_vm.isNew &&
                  (_vm.formData.submittedBy === _vm.user.number ||
                    _vm.formData.submittedBy === '')
                )?_c(VBtn,{staticClass:"ml-16",attrs:{"icon":"","x-small":"","color":"error lighten-1"},on:{"click":function($event){return _vm.deleteFile(file)}}},[_c(VIcon,[_vm._v("mdi-delete")])],1):_vm._e()],1)],1)}),1)],1):_vm._e(),(
          !_vm.isNew &&
          (this.formData.submittedBy === this.user.number ||
            this.formData.submittedBy === '')
        )?_c('div',{staticClass:"border",style:(_vm.formData.attachments.length === 0 ? 'width: 100%' : 'width: 50%'),attrs:{"flex-grow-1":""},on:{"click":function($event){return _vm.$refs.uploader.$refs.input.click()}}},[_c('div',{staticClass:"mx-auto d-flex"},[_c(VIcon,{staticClass:"mr-3",attrs:{"size":40}},[_vm._v(" mdi-cloud-upload-outline ")]),_c('div',{staticClass:"my-auto"},[_c('span',[_vm._v("Click here to add files")])]),_c(VFileInput,{ref:"uploader",attrs:{"accept":"application/pdf,.jpeg,.png,.jpg,GIF","hide-input":"","prepend-icon":"","multiple":""},on:{"change":_vm.uploadFile}})],1)]):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }