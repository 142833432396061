<template>
  <div>
    <ImprestBreadcrumb />
    <router-view />
    <Approve />
  </div>
</template>

<script>
import Approve from "../components/Approve";
import ImprestBreadcrumb from "../components/ImprestBreadcrumb";
import ImprestMixin from "../ImprestMixin.js";
export default {
  name: "Imprest",
  mixins: [ImprestMixin],
  components: {
    Approve,
    ImprestBreadcrumb,
  },
};
</script>

<style scoped></style>
