<template>
  <v-dialog v-model="setDialog" max-width="500px" persistent>
    <v-card tile>
      <v-card-title color="primary"> Edit Dimensions </v-card-title>

      <v-card-text class="mt-3">
        <v-form v-model="isValid" ref="DimensionsForm">
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="speedKey"
                    :items="speedKeys"
                    :item-text="(item) => item.speedkeyCode"
                    :item-value="(item) => item.speedkeyCode"
                    v-if="speedKeys.length > 0"
                    label="Select Spped Key"
                    :disabled="!displayImprestLineActions"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="expenseItem.fundNo"
                    :items="funds"
                    :item-text="(item) => item.no + ' - ' + item.name"
                    :item-value="(item) => item.no"
                    v-if="funds.length > 0"
                    label="Select Fund"
                    :disabled="!displayImprestLineActions"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="expenseItem.shortcutDimension1Code"
                    :items="dimension1"
                    :item-text="(item) => item.code + ' - ' + item.name"
                    :item-value="(item) => item.code"
                    v-if="dimension1.length > 0"
                    :label="`${dimension1[0]['dimensionApi']['codeCaption']}`"
                    :disabled="!displayImprestLineActions"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="expenseItem.shortcutDimension2Code"
                    :items="dimension2"
                    :item-text="(item) => item.code + ' - ' + item.name"
                    :item-value="(item) => item.code"
                    v-if="dimension2.length > 0"
                    :label="`${dimension2[0]['dimensionApi']['codeCaption']}`"
                    :disabled="!displayImprestLineActions"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="expenseItem.shortcutDimension3Code"
                    :items="dimension3"
                    :item-text="(item) => item.code + ' - ' + item.name"
                    :item-value="(item) => item.code"
                    v-if="dimension3.length > 0"
                    :label="`${dimension3[0]['dimensionApi']['codeCaption']}`"
                    :disabled="!displayImprestLineActions"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="expenseItem.shortcutDimension4Code"
                    :items="dimension4"
                    :item-text="(item) => item.code + ' - ' + item.name"
                    :item-value="(item) => item.code"
                    v-if="dimension4.length > 0"
                    :label="`${dimension4[0]['dimensionApi']['codeCaption']}`"
                    :disabled="!displayImprestLineActions"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="expenseItem.globalDimension5Code"
                    :items="dimension5"
                    :item-text="(item) => item.code + ' - ' + item.name"
                    :item-value="(item) => item.code"
                    v-if="dimension5.length > 0"
                    :label="`Select ${dimension5[0]['dimensionApi']['codeCaption']}`"
                    :disabled="!displayImprestLineActions"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="expenseItem.globalDimension6Code"
                    :items="dimension6"
                    :item-text="(item) => item.code + ' - ' + item.name"
                    :item-value="(item) => item.code"
                    v-if="dimension6.length > 0"
                    :label="`Select ${dimension6[0]['dimensionApi']['codeCaption']}`"
                    :disabled="!displayImprestLineActions"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="expenseItem.globalDimension7Code"
                    :items="dimension7"
                    :item-text="(item) => item.code + ' - ' + item.name"
                    :item-value="(item) => item.code"
                    v-if="dimension7.length > 0"
                    :label="`Select ${dimension7[0]['dimensionApi']['codeCaption']}`"
                    :disabled="!displayImprestLineActions"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="expenseItem.globalDimension8Code"
                    :items="dimension8"
                    :item-text="(item) => item.code + ' - ' + item.name"
                    :item-value="(item) => item.code"
                    v-if="dimension8.length > 0"
                    :label="`Select ${dimension8[0]['dimensionApi']['codeCaption']}`"
                    :disabled="!displayImprestLineActions"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text v-on:click="setDialog = false" color="error">Close</v-btn>
        <v-btn
          v-if="displayImprestLineActions"
          color="primary"
          @click="editDimensions()"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ImprestMixin from "../ImprestMixin.js";

export default {
  name: "DimensionsDialog.vue",
  mixins: [ImprestMixin],
  props: {
    itemDialog: Boolean,
    selectedExpenseItem: Object,
    formData: Object,
    dimensionValues: {
      type: Array,
      default: () => [],
    },
    displayImprestLineActions: Boolean,
  },
  data() {
    return {
      isValid: false,
    };
  },
  computed: {
    setDialog: {
      get() {
        return this.itemDialog;
      },
      set(val) {
        this.$emit("close-dialog", val);
      },
    },
    expenseItem: {
      get() {
        return this.selectedExpenseItem;
      },
      set(val) {
        return val;
      },
    },
    speedKey: {
      get() {
        return this.expenseItem.dimensionSpeedkeyCode;
      },
      set(val) {
        const speedKey = this.speedKeys
          .filter((el) => el.speedkeyCode === val)
          .shift();
        this.expenseItem.dimensionSpeedkeyCode = val;
        this.expenseItem.fundNo = speedKey.fundNo;
        this.expenseItem.shortcutDimension1Code = speedKey.globalDimension1Code;
        this.expenseItem.shortcutDimension2Code = speedKey.globalDimension2Code;
        this.expenseItem.shortcutDimension3Code = speedKey.globalDimension3Code;
        this.expenseItem.shortcutDimension4Code = speedKey.globalDimension4Code;
        this.expenseItem.globalDimension5Code = speedKey.globalDimension5Code;
        this.expenseItem.globalDimension6Code = speedKey.globalDimension6Code;
        this.expenseItem.globalDimension7Code = speedKey.globalDimension7Code;
        this.expenseItem.globalDimension8Code = speedKey.globalDimension8Code;
      },
    },
  },
  methods: {
    editDimensions: function () {
      this.$emit("updateDimensions", this.expenseItem);
      this.setDialog = false;
    },
  },
};
</script>
<style scoped>
@import "../styles/style.css";
</style>
