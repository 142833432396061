import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"fade-transition"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticClass:"mt-3",attrs:{"tile":"","elevation":"0"}},[_c(VDivider),_c(VContainer,{staticClass:"d-flex flex-row justify-center",staticStyle:{"min-height":"50vh"}},[_c(VCard,{attrs:{"min-width":"100%","flat":""}},[_c(VRow,{staticClass:"top-bar"},[_c(VCol,{staticClass:"text-center",attrs:{"cols":"6"}},[_c(VToolbar,{attrs:{"flat":"","color":"transparent"}},[_c('h3',{staticClass:"request-header"},[_vm._v("Pending Approvals")])])],1),_c(VCol,{staticClass:"text-center",attrs:{"cols":"6"}},[_c(VToolbar,{attrs:{"flat":"","color":"transparent"}},[_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c(VCardText,[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.pendingImprests,"search":_vm.search,"items-per-page":5,"fixed-header":"","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"custom-filter":_vm.customFilter},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.employeeNo",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getEmployee(item.employeeNo)))])]}},{key:"item.postingDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.postingDate)))])]}},{key:"item.amountToPayHeader",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("currencyFormat")(item.amountToPayHeader)))])]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.startDate)))])]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.endDate)))])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.readMore(item.description, 15)))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-start"},[_c(VChip,{staticClass:"mx-1",attrs:{"small":"","color":"success","label":"","to":{
                        name: 'ImprestApprove',
                        params: { approveCode: item.no },
                      }}},[_c(VIcon,{attrs:{"small":"","left":"","color":"white"}},[_vm._v("mdi-eye")]),_vm._v(" view ")],1)],1)]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }