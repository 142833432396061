import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VAppBar,{attrs:{"dense":"","flat":""}},[_c('div',{staticClass:"text-lg-h5",staticStyle:{"font-weight":"300"}},[_vm._v("Job Description")]),_c(VSpacer),_c(VBreadcrumbs,{attrs:{"customDivider":"","divider":"/","large":"","items":_vm.path}})],1),_c('div',{staticStyle:{"height":"85vh","overflow-y":"scroll"},attrs:{"id":"scollStyle"}},[_c('pdf',{ref:"jobDescription",attrs:{"src":("data:application/pdf;base64," + (_vm.jobDescription[0]))}})],1),_c(VBtn,{staticStyle:{"position":"fixed","right":"2%","bottom":"10%"},attrs:{"color":"primary","rounded":""},on:{"click":function($event){return _vm.downloadFile(_vm.jobDescription[0])}}},[_c(VIcon,{staticClass:"mx-3"},[_vm._v("print")]),_vm._v(" Download ")],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }