<template>
  <v-container fluid>
    <DiscBreadcrumbs />
    <router-view name="action" />
    <router-view name="view" />
  </v-container>
</template>
<script>
import DiscBreadcrumbs from "../components/DiscBreadcrumbs.vue";
export default {
  name: "Disciplinary",
  components: {
    DiscBreadcrumbs,
  },
  data() {
    return {
      //
    };
  },
};
</script>
