import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c('h2',{staticClass:"text-center font-weigth-black",attrs:{"max-width":"374"}},[_vm._v("Signup")]),_c(VCard,{staticClass:"mt-15 mx-auto",attrs:{"elevation":"2","max-width":"800"}},[_c(VCardText,[_c(VForm,{ref:"signupForm",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VTextField,{attrs:{"label":"First Name","rules":_vm.rules.name,"outlined":""},model:{value:(_vm.first),callback:function ($$v) {_vm.first=$$v},expression:"first"}}),_c(VTextField,{attrs:{"label":"Email","rules":_vm.rules.email,"outlined":""},model:{value:(_vm.last),callback:function ($$v) {_vm.last=$$v},expression:"last"}}),_c(VTextField,{attrs:{"label":"Password","type":_vm.value ? 'password' : 'text',"rules":_vm.rules.pwdRules,"append-icon":_vm.value ? 'visibility' : 'visibility_off',"outlined":""},on:{"click:append":function () { return (_vm.value = !_vm.value); }},model:{value:(_vm.pw1),callback:function ($$v) {_vm.pw1=$$v},expression:"pw1"}}),_c(VTextField,{attrs:{"label":"Confirm password","type":_vm.value ? 'password' : 'text',"rules":_vm.rules.pwdRules,"append-icon":_vm.value ? 'visibility' : 'visibility_off',"outlined":""},on:{"click:append":function () { return (_vm.value = !_vm.value); }},model:{value:(_vm.pw2),callback:function ($$v) {_vm.pw2=$$v},expression:"pw2"}})],1)],1),_c('div',{staticClass:"text-xs-center"},[_c(VCardActions,{staticClass:"text-xs-center"},[_c(VSpacer),_c(VBtn,{attrs:{"elevation":"2","color":"teal"}},[_vm._v(" Submit")]),_c(VSpacer)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }