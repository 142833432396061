import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pb-10"},[_c('div',{staticClass:"d-flex flex-column mt-7"},[_c('Logo',{staticClass:"align-self-center py-10",attrs:{"width":'10%',"height":'10%'}})],1),_c(VCard,{staticClass:"mx-auto loginCard",attrs:{"elevation":"10"}},[_c('div',{staticClass:"px-10"},[_c(VCardTitle,{staticClass:"text-center"},[_c(VSpacer),_vm._v(" Request Credentials "),_c(VSpacer)],1),_c(VCardText,[_c(VForm,{ref:"requestCredentials",on:{"submit":function($event){$event.preventDefault();return _vm.requestCredentials.apply(null, arguments)}}},[_c(VTextField,{attrs:{"outlined":"","dense":"","label":"Email Address","append-icon":"email","rules":[_vm.rules.email.required, _vm.rules.email.value]},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}}),_c(VBtn,{staticClass:"mt-2",attrs:{"color":"primary","block":"","type":"submit"}},[_c('div',[_vm._v("Request Credentials")])])],1)],1)],1)]),_c('auth-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }