<template>
  <v-container fluid class="mb-n4 mt-n3">
    <!--BEGIN breadcrumbs-->
    <v-breadcrumbs :items="breadcrumbs" class="breadcrumbs"></v-breadcrumbs>
    <!--END breadcrumbs-->
  </v-container>
</template>

<script>
export default {
  name: "ImprestBreadcrumb",
  computed: {
    breadcrumbs() {
      return this.$store.getters["imprest/breadcrumbs"];
    },
  },
};
</script>

<style scoped>
.breadcrumbs {
  padding-left: 0;
  margin-left: 0;
}
</style>
