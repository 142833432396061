import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c('StatsView',{attrs:{"data":_vm.storageStats},scopedSlots:_vm._u([{key:"stats",fn:function(){return [_c(VLayout,{staticClass:"mt-n4",attrs:{"wrap":""}},_vm._l((_vm.storageStats),function(item,i){return _c(VFlex,{key:i,staticClass:"lg5-custom",attrs:{"xs6":"","sm4":"","md3":"","xl2":""}},[_c(VHover,{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{staticClass:"mx-2 mt-2",class:{ 'on-hover': hover },staticStyle:{"background-color":"#ffffff"},attrs:{"flat":"","elevation":hover ? 16 : 0}},[_c(VList,[_c(VListItem,{staticClass:"stats"},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-lg-h5 stats-info font-weight-bold"},[_vm._v(_vm._s(item.count))]),_c(VListItemSubtitle,{staticClass:"stats-info"},[_vm._v(_vm._s(item.name))])],1),_c(VListItemAction,[_c(VAvatar,{attrs:{"color":item.avatarColor,"size":"53"}},[_c(VIcon,{attrs:{"x-large":"","color":item.iconColor}},[_vm._v(_vm._s(item.icon))])],1)],1)],1)],1)],1)]}}],null,true)})],1)}),1)]},proxy:true}])}),_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VCard,{staticClass:"mt-6"},[_c('PieChart',{attrs:{"pieData":_vm.pieData}})],1)],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VCard,{staticClass:"mt-6"},[_c('BarChart',{attrs:{"barData":_vm.barData}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }