<template>
  <v-dialog persistent v-model="dialog" hide-overlay max-width="60%">
    <v-card class="mx-auto" max-height="80vh">
      <v-app-bar flat dark dense color="primary" elevation="0">
        <v-app-bar-title class="text-lg-h6">
          Settle Imprest {{ imprestNo }}
        </v-app-bar-title>
        <v-spacer></v-spacer>
        <v-btn dark text @click="updateImprest"> Submit </v-btn>
        <v-btn text @click="setDialog = false"> Close </v-btn>
      </v-app-bar>
      <v-alert class="ma-3" text type="info" dense>
        Please make sure all imprests are accounted for before submission
      </v-alert>
      <v-expansion-panels v-model="panel" multiple class="pt-3" readonly>
        <v-expansion-panel expand v-model="panel">
          <v-expansion-panel-content v-for="(item, i) in imprestLines" :key="i">
            <v-card outlined>
              <v-expansion-panel-header color="indigo lighten-5" expand-icon="">
                <v-row class="d-flex">
                  <div class="mt-3">
                    <div class="font-weight-bold mb-2" color="transparent">
                      {{ item.expenseCode }}
                      <v-divider vertical />
                      {{ item.description }}
                    </div>
                  </div>
                  <v-spacer></v-spacer>
                  <div class="d-flex mt-3">
                    <span class="font-weight-bold px-3">
                      Original Amount:
                    </span>
                    <v-chip label class="font-weight-bold mr-2 mt-n2">
                      {{ item.amountToPay | currencyFormat }}
                    </v-chip>
                  </div>
                  <v-spacer></v-spacer>
                  <v-chip
                    label
                    class="white--text"
                    :color="
                      item.detailedImprestLines.length !== 0
                        ? 'green lighten-1'
                        : 'orange lighten-1'
                    "
                    >{{
                      item.detailedImprestLines.length !== 0
                        ? "Accounted"
                        : "Not Accounted"
                    }}</v-chip
                  >
                </v-row>
              </v-expansion-panel-header>
              <v-card>
                <v-data-table
                  :items="item.detailedImprestLines"
                  :headers="detailedLinesHeaders"
                  disable-filtering
                  hide-default-footer
                >
                  <!--eslint-disable-next-line -->
                  <template v-slot:item.amount="{ item }">
                    {{ item.amount | currencyFormat }}
                  </template>
                  <!--eslint-disable-next-line -->
                  <template v-slot:item.action="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          text
                          color="primary"
                          elevation="0"
                          class="white--text text-capitalize"
                          @click="download(item.attachment)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon class="mx-2">mdi-download</v-icon>
                        </v-btn>
                      </template>
                      <span>Download</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-divider class="mb-2" />
                <div class="d-flex justify-end">
                  <span class="font-weight-bold mt-1 px-3"> Balance: </span>
                  <v-chip label class="font-weight-bold mr-2 mb-2">
                    {{ item.balance | currencyFormat }}
                  </v-chip>
                </div>
              </v-card>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </v-dialog>
</template>

<script>
import helpers from "@kinetics254/cassandra-base/utilities/helpers/helper";

export default {
  props: {
    imprestLines: Array,
    dialog: Boolean,
    imprestNo: String,
    Currency: {
      type: String,
      default: "KES",
    },
  },
  data: () => ({
    panel: [0],
  }),
  name: "accountedList",
  computed: {
    detailedLinesHeaders() {
      return [
        { text: "#Entry No", sortable: true, value: "entryNo", align: "start" },
        { text: "Receipt No.", sortable: true, value: "description" },
        { text: "Attachment", sortable: true, value: "action" },
        {
          text: `Amount (${this.Currency})`,
          sortable: true,
          value: "amount",
          align: "end",
        },
      ];
    },
    setDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("close-dialog", val);
      },
    },
  },
  methods: {
    updateImprest: function () {
      const data = {
        documentType: "Imprest",
        no: this.imprestNo,
        imprestStatus: "Accounted",
      };
      this.$store.dispatch("imprest/settleImprest", data);
      this.setDialog = false;
      this.$router.push("/imprest/list");
    },
    download(item) {
      const a = document.createElement("a");
      a.href = `data:${helpers.detectMimeType(item)};base64,${item}`;
      a.download = `${Date.now()}_receipt${helpers.fileExtension(item)}`;
      a.click();
    },
  },
};
</script>
