import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VCard,{staticClass:"mx-auto",attrs:{"flat":""}},[_c(VCardTitle,[_vm._v("Installed Packages & Modules")]),_c(VCardText,_vm._l((_vm.links),function(link,i){return _c(VList,{key:i},[(
              !link.hasSubs && !link.hasSideLinks && link.name !== undefined
            )?_c(VListItem,{key:i,staticClass:"primary--text"},[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(_vm._s(link.icon))])],1),_c(VListItemTitle,[_vm._v(_vm._s(link.name))]),_c(VListItemAction,[_c(VSwitch,{attrs:{"inset":"","value":link.enabled,"true-value":true,"false-value":false},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"primary--text mx-2"},[_vm._v(" "+_vm._s(link.enabled ? "Enabled" : "Disabled")+" ")])]},proxy:true}],null,true),model:{value:(link.enabled),callback:function ($$v) {_vm.$set(link, "enabled", $$v)},expression:"link.enabled"}})],1)],1):_vm._e(),(link.hasSubs)?_c(VListGroup,{attrs:{"value":false},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(_vm._s(link.icon))])],1),_c(VListItemTitle,{staticClass:"primary--text"},[_vm._v(_vm._s(link.name))]),_c(VListItemAction,[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c(VSwitch,{attrs:{"inset":"","value":link.enabled,"true-value":true,"false-value":false},on:{"click":function($event){$event.stopPropagation();}},model:{value:(link.enabled),callback:function ($$v) {_vm.$set(link, "enabled", $$v)},expression:"link.enabled"}})],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(link.enabled ? "Enabled" : "Disabled")+" ")])])],1)]},proxy:true}],null,true)},_vm._l((link.subs),function(sub,idx){return _c(VListItem,{key:idx,staticClass:"primary--text"},[_c(VListItemIcon,{staticClass:"ml-10"},[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(_vm._s(sub.icon))])],1),_c(VListItemTitle,{staticClass:"ml-1"},[_vm._v(_vm._s(sub.name))]),_c(VListItemAction,[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c(VSwitch,{attrs:{"inset":"","value":sub.enabled,"true-value":true,"false-value":false},model:{value:(sub.enabled),callback:function ($$v) {_vm.$set(sub, "enabled", $$v)},expression:"sub.enabled"}})],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(sub.enabled ? "Enabled" : "Disabled")+" ")])])],1)],1)}),1):_vm._e(),(link.hasSideLinks)?_c(VListGroup,{attrs:{"value":false},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(_vm._s(link.icon))])],1),_c(VListItemTitle,{staticClass:"primary--text"},[_vm._v(_vm._s(link.name))]),_c(VListItemAction,[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c(VSwitch,{attrs:{"inset":"","value":link.enabled,"true-value":true,"false-value":false},on:{"click":function($event){$event.stopPropagation();}},model:{value:(link.enabled),callback:function ($$v) {_vm.$set(link, "enabled", $$v)},expression:"link.enabled"}})],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(link.enabled ? "Enabled" : "Disabled")+" ")])])],1)]},proxy:true}],null,true)},_vm._l((link.links),function(sideLink,idx){return _c(VListItem,{key:idx,staticClass:"primary--text"},[_c(VListItemIcon,{staticClass:"ml-10"},[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(_vm._s(sideLink.icon))])],1),_c(VListItemTitle,{staticClass:"ml-1"},[_vm._v(_vm._s(sideLink.name))]),_c(VListItemAction,[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c(VSwitch,{attrs:{"inset":"","value":sideLink.enabled,"true-value":true,"false-value":false},model:{value:(sideLink.enabled),callback:function ($$v) {_vm.$set(sideLink, "enabled", $$v)},expression:"sideLink.enabled"}})],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(sideLink.enabled ? "Enabled" : "Disabled")+" ")])])],1)],1)}),1):_vm._e()],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }