<template>
  <v-container>
    <PettyCashBreadcrumbs />
    <router-view></router-view>
  </v-container>
</template>
<script>
import PettyCashBreadcrumbs from "../components/PettyCashBreadCrumbs.vue";
export default {
  name: "PettyCash",
  components: { PettyCashBreadcrumbs },
  data: function () {
    return {};
  },
};
</script>
