<template>
  <v-container class="mt-10 fade-transition">
    <v-row>
      <v-col cols="12">
        <v-sheet color="transparent" elevation="0" tile>
          <v-row dense>
            <v-col class="d-flex" cols="12" md="6">
              <v-card width="100%" elevation="0" tile>
                <v-card-title class="overline">
                  Salary Advance

                  <v-spacer />

                  <v-btn @click="applicationDialog = true" color="primary">
                    Apply
                  </v-btn>
                </v-card-title>

                <v-card-text>
                  <v-row>
                    <v-col
                      class="py-5 d-flex flex-column justify-center text-center overline"
                    >
                      <h4 class="subtitle-2">TOTAL BALANCE</h4>
                      <h1 class="text-h3 font-weight-light green--text">
                        {{
                          (total.amount -
                            (total.loanDeductions + total.advanceDeductions))
                            | currency
                        }}
                      </h1>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-actions>
                  <v-sheet
                    class="overline px-3 d-flex flex-column text-center align-items-center"
                  >
                    <h4 class="subtitle-2">
                      {{
                        (total.advances - total.advanceDeductions) | currency
                      }}
                    </h4>
                    <h6 class="caption primary--text">ADVANCE BALANCE</h6>
                  </v-sheet>
                  <v-spacer />
                  <v-sheet
                    class="overline px-3 d-flex flex-column text-center align-items-center"
                  >
                    <h4 class="subtitle-2">
                      {{ maxBalance | currency }}
                    </h4>
                    <h6 class="caption green--text">ADVANCE LIMIT AMOUNT</h6>
                  </v-sheet>
                  <v-spacer />
                  <v-sheet
                    class="overline px-3 d-flex flex-column text-center align-items-center"
                  >
                    <h4 class="subtitle-2">
                      {{
                        (total.advanceDeductions + total.loanDeductions)
                          | currency
                      }}
                    </h4>
                    <h6 class="caption orange--text">TOTAL DEDUCTIONS</h6>
                  </v-sheet>
                </v-card-actions>
              </v-card>
            </v-col>

            <v-col class="d-flex" cols="12" md="3">
              <v-sheet
                width="100%"
                class="overline text-center d-flex flex-column justify-center align-center pa-5"
                elevation="1"
                dark
                color="primary"
              >
                <p class="mt-3 text-h6">ADVANCES</p>
                <h1 class="overline text-h5 mt-3">
                  {{ total.advances | currency }}
                </h1>
              </v-sheet>
            </v-col>

            <v-col class="d-flex" cols="12" md="3">
              <v-sheet
                width="100%"
                class="overline text-center d-flex flex-column justify-center align-center pa-5"
                elevation="1"
                dark
                color="success"
              >
                <p class="mt-3 text-h6">LOANS</p>
                <h1 class="overline text-h5 mt-3">
                  {{ total.loan | currency }}
                </h1>
              </v-sheet>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>

      <v-col cols="12">
        <v-card tile class="mt-3" elevation="0">
          <v-tabs grow centered v-model="tab" active-class="indigo lighten-5">
            <v-tab>Applications</v-tab>
            <v-tab>Deductions</v-tab>

            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-container
                  style="min-height: 50vh"
                  class="d-flex flex-row justify-center"
                >
                  <v-card color="transparent" min-width="100%" flat>
                    <v-card-text>
                      <v-data-table :headers="headers" :items="advances">
                        <template v-slot:item.status="{ item }">
                          <v-chip
                            small
                            dark
                            :color="getStatusColor(item.status).color"
                          >
                            {{ item.status }}
                          </v-chip>
                        </template>

                        <template v-slot:item.applicationDate="{ item }">
                          {{ formatDate(item.applicationDate, 5) }}
                        </template>

                        <template v-slot:item.postingDate="{ item }">
                          {{ formatDate(item.postingDate, 5) }}
                        </template>

                        <template v-slot:item.actions="{ item }">
                          <v-icon
                            small
                            class="mr-2"
                            color="blue"
                            @click="viewItem(item)"
                          >
                            mdi-eye
                          </v-icon>
                          <v-icon
                            small
                            class="mr-2"
                            color="blue"
                            v-if="item.status === 'Open'"
                            @click="editItem(item)"
                          >
                            mdi-pencil
                          </v-icon>
                          <v-icon
                            small
                            color="red"
                            v-if="item.status === 'Open'"
                            @click="remove(item)"
                          >
                            mdi-delete
                          </v-icon>
                        </template>

                        <template v-slot:item.submit="{ item }">
                          <v-btn
                            v-if="item.status === 'Open'"
                            tile
                            outlined
                            @click="sendApprovalRequest(item)"
                            small
                            color="primary"
                          >
                            Submit for approval
                            <v-icon right> mdi-send </v-icon>
                          </v-btn>
                          <v-btn
                            v-if="item.status === 'Pending Approval'"
                            tile
                            outlined
                            @click="cancelApprovalRequest(item)"
                            small
                            color="primary"
                          >
                            Cancel Approval Request
                            <v-icon right> mdi-send </v-icon>
                          </v-btn>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-container>
              </v-tab-item>
              <v-tab-item>
                <v-container
                  style="min-height: 50vh"
                  class="d-flex flex-row justify-center"
                >
                  <v-card color="transparent" min-width="100%" flat>
                    <v-card-text>
                      <v-data-table
                        :headers="deductionHeaders"
                        :items="deductions"
                      >
                        <template v-slot:item.applicationDate="{ item }">
                          {{ formatDate(item.applicationDate, 5) }}
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="applicationDialog" max-width="670px">
      <v-card tile>
        <v-card-title> Salary Advance Application </v-card-title>

        <v-card-text class="mt-3">
          <v-select
            dense
            :items="['Advance', 'Staff Loan']"
            outlined
            label="Advance Type"
            v-model="formData.advanceType"
            :rules="rules.advanceType"
            ref="advanceType"
          />
          <v-select
            dense
            v-if="formData.advanceType === 'Staff Loan'"
            :items="loanTypes"
            item-text="description"
            item-value="code"
            outlined
            label="Loan Type"
            v-model="formData.loanNo"
            :rules="rules.loanNo"
            ref="loanNo"
          />
          <v-text-field
            dense
            type="number"
            outlined
            label="Advance Amount"
            v-model="formData.applicationAmount"
            :rules="rules.applicationAmount"
            ref="applicationAmount"
          />
          <v-text-field
            dense
            :disabled="formData.advanceType !== 'Staff Loan'"
            type="number"
            outlined
            label="Instalments"
            v-model="formData.repaymentInstallments"
            :rules="rules.repaymentInstallments"
            ref="repaymentInstallments"
          />
          <v-text-field
            dense
            type="date"
            outlined
            label="Disbursement Date"
            v-model="formData.preferredDisbursementDate"
            :rules="rules.preferredDisbursementDate"
            ref="preferredDisbursementDate"
          />
          <v-textarea
            dense
            rows="3"
            outlined
            label="Application Reason"
            v-model="formData.applicationReason"
            :rules="rules.applicationReason"
            ref="applicationReason"
          />
        </v-card-text>

        <v-card-actions v-if="formData.status !== 'Released'">
          <v-spacer />
          <v-btn text @click="clearForm()">Cancel</v-btn>
          <v-btn v-if="edit" color="primary" @click="update">Save</v-btn>
          <v-btn v-else color="primary" @click="save">Save</v-btn>
        </v-card-actions>
        <ApprovalEntries
          :approvalEntries="approvalEntries.length !== 0 ? approvalEntries : []"
        />
        <v-card-actions v-if="formData.status === 'Released'">
          <v-spacer />
          <v-btn color="red" @click="clearForm()">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import DateMixin from "@kinetics254/cassandra-base/mixins/DateMixin";
import { helper } from "../../cassandra-base/utilities";
import { AuthService } from "@kinetics254/cassandra-base/modules/auth";
import moment from "moment";
import ApprovalEntries from "./ApprovalEntries.vue";

export default {
  name: "AdvanceList",
  mixins: [DateMixin],
  components: { ApprovalEntries },
  data: function () {
    return {
      applicationDialog: false,
      edit: false,
      tab: null,
      formData: {
        advanceType: "Advance",
        loanNo: "",
        applicationAmount: 0,
        repaymentInstallments: 1,
        preferredDisbursementDate: "",
        applicationReason: "",
      },
      deductions: [],
      headers: [
        {
          text: "#no",
          align: "start",
          sortable: true,
          value: "no",
        },
        { text: "Type", sortable: true, value: "advanceType" },
        { text: "Amount", sortable: true, value: "applicationAmount" },
        { text: "Status", sortable: true, value: "status" },
        { text: "Application Date", sortable: true, value: "applicationDate" },
        { text: "Posting Date", sortable: true, value: "postingDate" },
        { text: "Actions", value: "actions", sortable: false },
        { text: "", value: "submit", sortable: false },
      ],
      deductionHeaders: [
        {
          text: "Advance No",
          align: "start",
          sortable: true,
          value: "code",
        },
        { text: "Type", sortable: true, value: "type" },
        { text: "Amount Deducted", sortable: true, value: "amount" },
        { text: "Deduction Period", sortable: true, value: "deductionPeriod" },
        { text: "Application Date", sortable: true, value: "applicationDate" },
      ],
    };
  },

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Advance/getLoanTypes");
      v.$store.dispatch("Advance/getPeriods");
      v.$store.dispatch("Advance/getSalaryAdvance");
      v.$store.dispatch("Advance/getBalance");
      v.$store.dispatch("Setup/getLeaveEmployees");
    });
  },

  computed: {
    rules() {
      return {
        advanceType: [(v) => !!v || "Required"],
        applicationAmount: [(v) => !!v || "Required"],
        preferredDisbursementDate: [(v) => !!v || "Required"],
        repaymentInstallments: [
          (v) => !!v || "Required",
          (v) => (v && v > 0) || "Period should be above 0",
          (v) => (v && v <= 12) || "Period cannot be more than 12",
        ],
        applicationReason: [(v) => !!v || "Required"],
        loanNo: [
          (v) =>
            (this.formData.advanceType === "Staff Loan" && !!v) || "Required",
        ],
      };
    },

    maxBalance() {
      return this.$store.getters["Advance/balance"];
    },

    advances() {
      const advance = this.$store.getters["Advance/advance"];

      let total = 0;
      advance.map((a, i) => {
        total = 0;
        this.deductions = [...a.deduction].map((d) => {
          total += d.amount;
          return {
            ...d,
            type: a.advanceType,
            code: a.no,
            applicationDate: a.applicationDate,
            deductionPeriod: this.getPeriodName(d.period),
          };
        });

        a.deducted = total;

        return a;
      });

      return advance;
    },

    periods() {
      return this.$store.getters["Advance/periods"];
    },

    loanTypes() {
      return this.$store.getters["Advance/loanType"];
    },

    total() {
      const total = {
        amount: 0,
        loanDeductions: 0,
        advanceDeductions: 0,
        advances: 0,
        loan: 0,
      };

      this.advances.forEach((a) => {
        // TODO enable after approval
        // if (a.status === 'Pending Approval' && (a.deducted < a.applicationAmount)) { total.amount += a.applicationAmount }
        if (a.disbursed) {
          total.amount += a.applicationAmount;

          if (a.advanceType === "Advance") {
            total.advances += a.applicationAmount;
            const deduction =
              this.periods.length !== 0
                ? a.deduction.filter(
                    (el) =>
                      new Date(el.period).getMonth() ===
                      new Date(this.periods[0].startDate).getMonth()
                  )
                : [];
            total.advanceDeductions += deduction.reduce(
              (c, b) => c + b.amount,
              0
            );
          }

          if (a.advanceType === "Staff Loan") {
            total.loan += a.applicationAmount;
            total.loanDeductions += a.deducted;
          }

          total.deductions += a.deducted;
        }
      });

      return total;
    },

    currencies() {
      return this.$store.getters["Advance/currencies"];
    },

    approvalEntries() {
      return this.$store.getters["Advance/approvalEntries"];
    },
  },

  methods: {
    getStatusColor: function (status) {
      return helper.getColor(status);
    },

    editItem: function (payload) {
      this.formData = { ...payload };
      this.edit = true;
      this.applicationDialog = true;
    },
    viewItem: function (payload) {
      this.formData = { ...payload };
      this.applicationDialog = true;
      this.edit = false;
      this.$store.dispatch("Advance/getApprovalEntries", payload.no);
    },

    clearForm: function () {
      this.formData = {
        advanceType: "Advance",
        applicationAmount: 0,
        preferredDisbursementDate: "",
        repaymentInstallments: 1,
        loanNo: "",
        applicationReason: "",
      };

      this.edit = false;
      this.applicationDialog = false;
    },

    save: function () {
      let isValid = true;
      for (const key in this.formData) {
        if (this.$refs[key]) {
          isValid = isValid ? this.$refs[key].validate(true) : false;
        }
      }

      if (!isValid) return;

      this.$store.dispatch("Advance/saveSalaryAdvance", {
        ...this.formData,
        ...{ applicationAmount: parseInt(this.formData.applicationAmount) },
        ...{
          repaymentInstallments: parseInt(this.formData.repaymentInstallments),
        },
        applicationDate: moment().format("YYYY-MM-DD"),
        employeeCode: this.$store.getters["auth/user"].employee,
        payrollPeriod: this.periods.filter((p) => p.current).shift()
          .startingDate,
      });

      this.clearForm();
    },

    sendApprovalRequest: function (item) {
      this.$confirm.show({
        title: "Submit this application for approval?",
        text: "Are you sure you want to submit?",
        onConfirm: () => {
          const data = { docNo: item.no };
          this.$store.dispatch("Advance/SendApprovalRequest", data);
        },
      });
    },

    cancelApprovalRequest: function (item) {
      this.$confirm.show({
        title: "Cancel approval Request?",
        text: "Are you sure you want to cancel approval Request?",
        onConfirm: () => {
          const data = { docNo: item.no };
          this.$store.dispatch("Advance/CancelApprovalRequest", data);
        },
      });
    },

    update: function () {
      let isValid = true;
      for (const key in this.formData) {
        if (this.$refs[key]) {
          isValid = isValid ? this.$refs[key].validate(true) : false;
        }
      }

      if (!isValid) return;

      this.$store.dispatch("Advance/updateSalaryAdvance", {
        ...this.formData,
        ...{ applicationAmount: parseInt(this.formData.applicationAmount) },
        ...{
          repaymentInstallments: parseInt(this.formData.repaymentInstallments),
        },
      });
      this.clearForm();
    },

    remove: function (item) {
      this.$confirm.show({
        title: "Delete this application?",
        text: "Are you sure you want to delete?",
        onConfirm: () => {
          this.$store.dispatch("Advance/deleteSalaryAdvance", { ...item });
        },
      });

      this.clearForm();
    },

    getPeriodName: function (date) {
      const period = this.periods
        .filter((p) => {
          return new Date(p.startDate).getDate() === new Date(date).getDate();
        })
        .shift();
      return period ? period.name : "";
    },
  },
};
</script>

<style scoped></style>
