import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VDialog,{attrs:{"content-class":"imageDialog","light":"","overlay-opacity":"0.5","width":"80%"},model:{value:(_vm.OpenDialog),callback:function ($$v) {_vm.OpenDialog=$$v},expression:"OpenDialog"}},[_c(VBtn,{staticStyle:{"position":"absolute","right":"5em"},style:({
        float: 'right',
        color: '#000',
        marginTop: '-1%',
      }),attrs:{"icon":""},on:{"click":function($event){_vm.OpenDialog = false}}},[_c(VIcon,{attrs:{"size":"50"}},[_vm._v("mdi-close")])],1),_c(VImg,{staticStyle:{"max-height":"90%","max-width":"100%"},attrs:{"src":("data:application/jpg;base64," + _vm.Image)}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }