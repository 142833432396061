import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VDialog,{attrs:{"width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"justify-center grey lighten-2"},[_vm._v(" Leave Allowance ")]),_c(VCardText,{staticClass:"cardfont text-center font-weight-medium darken-2"},[_vm._v(" Do you want to apply for Leave allowance? ")]),_c(VDivider),_c(VCardActions,[_c(VBtn,{attrs:{"color":"error","text":""},on:{"click":[function($event){_vm.setDialog = false},_vm.SaveApplication]}},[_vm._v(" No ")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.OpenAllowanceForm}},[_vm._v(" Yes ")])],1)],1)],1),_c('div',[_c(VDialog,{attrs:{"width":"800","persistent":""},model:{value:(_vm.allowancedialog),callback:function ($$v) {_vm.allowancedialog=$$v},expression:"allowancedialog"}},[_c('allowance-form',{attrs:{"leaveData":this.leaveData,"allowancedialog":_vm.allowancedialog,"passmodal":_vm.passmodal},on:{"close-dialog":_vm.closeDialog}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }