import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_c(VBtn,{attrs:{"color":"error","text":""},on:{"click":function($event){return _vm.changeView('Stats')}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-arrow-left")]),_vm._v(" back ")],1)],1),_c(VCardText,[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.employeeItems},scopedSlots:_vm._u([{key:"item.returnable",fn:function(ref){
var item = ref.item;
return [_c('div',[_c(VSimpleCheckbox,{staticClass:"shrink mr-2",attrs:{"small":"","disabled":""},model:{value:(item.returnable),callback:function ($$v) {_vm.$set(item, "returnable", $$v)},expression:"item.returnable"}})],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }