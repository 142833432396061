<template>
  <v-card class="mx-auto" rounded flat>
    <v-card-title> Raised Disciplinary List </v-card-title>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        class="mx-2 mt-n2"
        style="width: 200px"
      ></v-text-field>
      <v-spacer />
      <v-btn class="primary text-capitalize" @click="raiseDisciplinary()">
        <v-icon left>mdi-plus</v-icon>
        Raise Disciplinary
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="raisedHeaders"
        :items="raisedItems"
        :search="search"
      >
        <!--eslint-disable-next-line -->
              <template v-slot:item.documentDate="{ item }">
          <span>{{ item.documentDate | date }}</span>
        </template>
        <!--eslint-disable-next-line -->
              <template v-slot:item.accused="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div class="d-flex" v-on="on" v-bind="attrs">
                <v-avatar class="secondary mr-2" size="35">
                  {{ getInititals(item.accusedName) }}
                </v-avatar>
                <div class="mt-2">
                  {{ item.accusedName }}
                </div>
              </div>
            </template>
            <span>Employee: {{ item.accused }}</span>
          </v-tooltip>
        </template>
        <!--eslint-disable-next-line -->
              <template v-slot:item.status="{ item }">
          <v-chip :class="`${getColor(item.status)}`" label>
            {{ item.status }}
          </v-chip>
        </template>
        <!--eslint-disable-next-line -->
              <template v-slot:item.actionDescription="{ item }">
          <span class="text-truncate" style="width: 40%"></span>
        </template>
        <!--eslint-disable-next-line -->
              <template v-slot:item.action="{ item }">
          <div class="d-flex justify-start">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  class="mx-1"
                  color="teal lighten-4"
                  label
                  small
                  @click="viewDisciplinary(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="teal darken-4" small>mdi-eye</v-icon>
                </v-chip>
              </template>
              <span>view</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-show="item.status === 'Open'"
                  class="mx-1"
                  color="red lighten-4"
                  label
                  small
                  v-bind="attrs"
                  @click="deleteDisciplinary(item)"
                  v-on="on"
                >
                  <v-icon color="red darken-4" small>mdi-delete</v-icon>
                </v-chip>
              </template>
              <span>remove</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import { AuthService } from "@kinetics254/cassandra-base/modules/auth";
import { helper } from "@kinetics254/cassandra-base/utilities";

export default {
  name: "RaisedList",
  data() {
    return {
      search: "",
      tabs: 0,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Disc/getRaisedDisciplinary", {
        raisedBy: v.user.employee,
      });
    });
  },
  computed: {
    user() {
      return AuthService.user;
    },
    raisedItems() {
      return this.$store.getters["Disc/discGetters"]("raisedDisciplinary");
    },
    raisedHeaders() {
      return [
        {
          text: "No.",
          value: "no",
          align: "left",
        },
        { text: "Raised On", value: "documentDate" },
        { text: "Accused Employee", value: "accused" },
        { text: "status", value: "status" },
        { text: "Action Description", value: "actionDescription" },
        { text: "Disciplinary stage", value: "stage" },
        { text: "actions", value: "action" },
      ];
    },
    accusedHeaders() {
      return [
        {
          text: "No.",
          value: "no",
          align: "left",
        },
        { text: "Raised On", value: "documentDate" },
        { text: "Raised By", value: "raisedBy" },
        { text: "status", value: "status" },
        { text: "action Description", value: "actionDescription" },
        { text: "stage", value: "stage" },
        { text: "actions", value: "action" },
      ];
    },
  },
  methods: {
    raiseDisciplinary() {
      this.$router.push({ name: "DisciplinaryCard" });
    },
    getInititals(name) {
      return name
        .split(" ")
        .map((n) => n[0])
        .join("");
    },
    getColor(status) {
      return helper.getColor(status).color;
    },
    viewDisciplinary(item) {
      this.$router.push({
        name: "DisciplinaryCard",
        params: { no: btoa(item.no) },
      });
    },
    deleteDisciplinary(item) {
      this.$confirm.show({
        title: "Delete Disciplinary",
        text: "Are you sure you want to delete this disciplinary?",
        onConfirm: () => {
          this.$store.dispatch("Disc/deleteDisciplinary", { ...item });
        },
      });
    },
  },
};
</script>
