import call from "@kinetics254/cassandra-base/service/http";
import constants from "./PayslipConstants";

export default {
  namespaced: true,
  state: {
    periods: [],
    payslip: "",
  },
  mutations: {
    SET_PERIODS: (state, payload) => {
      state.periods = payload;
    },

    SET_PAYSLIP: (state, payload) => {
      state.payslip = payload;
    },
  },
  getters: {
    periods: (state) => state.periods,
    payslip: (state) => state.payslip,
  },

  actions: {
    getPeriods: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.periods)
        .then((res) => {
          commit("SET_PERIODS", res.data.data);
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    getPayslip: ({ commit }, period) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.payslip(period))
        .then((res) => {
          commit("SET_PAYSLIP", res.data.data);
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
  },
};
