<template>
  <v-card flat>
    <v-card-title> Attachments </v-card-title>
    <div class="d-flex flex-column">
      <v-alert
        type="error"
        dense
        text
        icon="mdi-cloud-alert"
        border="left"
        v-if="attachmentError"
      >
        Each attachment should be less than 2MB and not more than 5 attachments
      </v-alert>

      <v-col cols="12" class="d-flex" style="flex-direction: row">
        <div
          style="width: 50%"
          v-if="formData.attachments.length !== 0"
          flex-grow-0
        >
          <v-list>
            <v-list-item v-for="(file, i) in formData.attachments" :key="i">
              <v-list-item-icon>
                <v-chip label color="blue lighten-4" small>
                  <v-icon small color="blue">mdi-file-outline</v-icon>
                </v-chip>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  <v-card max-width="1000">
                    {{ file.fileName }}
                  </v-card>
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-icon>
                <v-btn
                  v-if="
                    !isNew &&
                    (formData.submittedBy === user.number ||
                      formData.submittedBy === '')
                  "
                  icon
                  x-small
                  color="error lighten-1"
                  class="ml-16"
                  @click="deleteFile(file)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </div>

        <div
          class="border"
          flex-grow-1
          @click="$refs.uploader.$refs.input.click()"
          v-if="
            !isNew &&
            (this.formData.submittedBy === this.user.number ||
              this.formData.submittedBy === '')
          "
          :style="
            formData.attachments.length === 0 ? 'width: 100%' : 'width: 50%'
          "
        >
          <div class="mx-auto d-flex">
            <v-icon :size="40" class="mr-3"> mdi-cloud-upload-outline </v-icon>

            <div class="my-auto">
              <span>Click here to add files</span>
            </div>

            <v-file-input
              accept="application/pdf,.jpeg,.png,.jpg,GIF"
              ref="uploader"
              hide-input
              prepend-icon=""
              multiple
              @change="uploadFile"
            />
          </div>
        </div>
      </v-col>
    </div>
  </v-card>
</template>

<script>
import helper from "@/packages/cassandra-base/utilities/helpers/helper";

export default {
  name: "TrainingAttachment",
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    isEdit: Boolean,
    isSupervisor: Boolean,
    canEdit: Boolean,
    user: Object,
    isNew: Boolean,
  },
  data() {
    return {
      attachmentError: false,
    };
  },
  methods: {
    uploadFile: async function (value) {
      if (value && value.length !== 0) {
        value.forEach((item) => {
          if (item.size > 2000000 || value.length > 5) {
            this.attachmentError = true;
          } else {
            const base64File = [];
            Promise.all([helper.toBase64(item)]).then((res) => {
              res = res[0].split(",")[1];
              if (
                this.formData.attachments.findIndex(
                  (el) => el.fileName === item.name
                ) === -1
              ) {
                this.formData.attachments.unshift({
                  attachment: res,
                  fileName: item.name,
                });
              }
            });
            this.attachmentError = false;
          }
        });
      }
    },
    deleteFile(file) {
      if (Object.hasOwnProperty.call(file, "lineNo")) {
        this.$store.dispatch("Training/deleteAttachment", file);
      } else {
        this.formData.attachments.splice(
          this.formData.attachments.indexOf(file),
          1
        );
      }
    },
  },
};
</script>

<style>
@import "../styles.css";
</style>
