import approvalRoutes from "./approvalRoutes";
import approvalStore from "./approvalStore";
import approvalLinks from "./approvalLinks";

export default {
  install(Vue, options) {
    if (options.router) {
      options.router.addRoutes(approvalRoutes);
    }
    if (options.store) {
      options.store.registerModule("Approvals", approvalStore);
    }
    options.store.commit("Dashboard/ADD_LINK", approvalLinks);
  },
};
