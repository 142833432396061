<template>
  <v-container>
    <v-card class="my-5 elevation-0" height="100%">
      <v-card-title>
        <v-btn text small @click="$router.back()">
          <v-icon left> mdi-arrow-left </v-icon>
          Back
        </v-btn>
        <v-spacer />
        <v-btn small color="secondary" @click="downloadLink(document)">
          <v-icon left> mdi-download </v-icon>
          Download
        </v-btn>
      </v-card-title>
      <v-divider />

      <v-row>
        <v-col cols="8" offset="2">
          <v-card-text class="elevation-1 my-1">
            <pdf
              style="width: 100%"
              :src="`data:application/pdf;base64,${document}`"
            />
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import pdf from "vue-pdf";
import FileMixin from "@kinetics254/cassandra-base/mixins/FileMixin";
export default {
  name: "PettycashDocument",
  mixins: [FileMixin],
  components: { pdf },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("pettycash/getPettyCashDocument", to.params.docNo);
    });
  },
  computed: {
    document() {
      return this.$store.getters["pettycash/pettycashstate"](
        "pettycashDocument"
      );
    },
  },
  methods: {},
};
</script>
