<template>
  <v-container>
    <v-app-bar
      class="leaveBar"
      dense
      style="background-color: #f0f3f4"
      :style="{ border: '0' }"
    >
      <v-toolbar-title class="text-h5 font-weight-bolder">
        Payslip
      </v-toolbar-title>

      <v-spacer />

      <v-breadcrumbs :items="crumbs">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>

        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            :href="item.href"
            class="text-capitalize"
            disabled
          >
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-app-bar>

    <router-view />
  </v-container>
</template>

<script>
export default {
  name: "Payslip",
  computed: {
    crumbs() {
      const pathArray = this.$route.path.split("/");

      pathArray.shift();

      return pathArray.reduce((breadcrumbArray, path, idx) => {
        breadcrumbArray.push({
          path: path,
          href: breadcrumbArray[idx - 1]
            ? "/" + breadcrumbArray[idx - 1].path + "/" + path
            : "/" + path,
          text: this.$route.matched[idx].name || path,
        });
        return breadcrumbArray;
      }, []);
    },
  },
};
</script>

<style scoped></style>
