export default {
  advance: (data) => `hrmis/payroll/advance${data ? "?no=" + data.no : ""}`,
  loanType: "hrmis/payroll/loan-types",
  periods: "hrmis/payroll/current_period",
  balance: "hrmis/payroll/balance",
  SendApprovalRequest: "hrmis/payroll/SendApprovalRequest",
  CancelApprovalRequest: "hrmis/payroll/CancelApprovalRequest",
  // approvalEntries: (id) => `approvals/approvalEntries/${id}`,
  approvalEntries: (params) =>
    `approvals/approvalEntries${"?documentNo=" + params}`,
};
