<template>
  <div id="main" style="width: 100%; height: 55vh"></div>
</template>
<script>
import * as echarts from "echarts";
import { AuthService } from "@kinetics254/cassandra-base/modules/auth";

export default {
  name: "MultiDataBarchart",
  props: {
    multiData: Object,
  },
  components: {},
  data: function () {
    return {};
  },
  watch: {
    multiData() {
      var chartDom = document.getElementById("main");
      var myChart = echarts.init(chartDom);
      myChart.setOption(this.option);
      myChart.resize();
    },
  },
  mounted() {
    var chartDom = document.getElementById("main");
    var myChart = echarts.init(chartDom);
    myChart.setOption(this.option);
    myChart.resize();
  },
  computed: {
    caption() {
      const user = AuthService.user;
      return user.captions.pettycash_caption;
    },
    colors() {
      return ["#91CC75", "#5470C6", "#EE6666", "#FF5733"];
    },
    option() {
      return {
        color: this.colors,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },
        grid: { containLabel: true },
        toolbox: {
          feature: {
            dataView: { show: true, readOnly: false },
            saveAsImage: { show: true },
          },
        },
        legend: {
          data: [
            "Leave",
            "Imprests",
            "Requisitions",
            `${this.caption ? this.caption : "Petty Cash"}`,
          ],
        },
        xAxis: [
          {
            type: "category",
            axisTick: {
              alignWithLabel: true,
            },
            // prettier-ignore
            data: this.multiData.labels,
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "Count",
            position: "left",
            alignTicks: true,
            axisLine: {
              show: true,
              lineStyle: {
                color: this.colors[0],
              },
            },
          },
          {
            type: "value",
            name: "Amount",
            position: "right",
            alignTicks: true,
            axisLine: {
              show: true,
              lineStyle: {
                color: this.colors[1],
              },
            },
          },
        ],
        series: [
          {
            name: "Leave",
            type: "bar",
            yAxisIndex: 0,
            data: this.multiData.data.leaveData,
          },
          {
            name: "Imprests",
            type: "bar",
            yAxisIndex: 0,
            data: this.multiData.data.imprestData,
          },
          {
            name: "Imprest Amount",
            type: "line",
            yAxisIndex: 1,
            smooth: true,
            color: "#91CC75",
            data: this.multiData.data.imprestAmount,
          },
          {
            name: "Requisitions",
            type: "bar",
            yAxisIndex: 0,
            data: this.multiData.data.reqData,
          },
          {
            name: "Requisition Amount",
            type: "line",
            yAxisIndex: 1,
            smooth: true,
            color: "#EE6666",
            data: this.multiData.data.reqAmount,
          },
          {
            name: `${this.caption ? this.caption : "Petty Cash"}`,
            type: "bar",
            yAxisIndex: 0,
            data: this.multiData.data.pettyCashData,
          },
          {
            name: "PettyCash Amount",
            type: "line",
            yAxisIndex: 1,
            smooth: true,
            color: "#FF5733",
            data: this.multiData.data.pettyCashAmount,
          },
        ],
      };
    },
  },
  methods: {},
};
</script>
