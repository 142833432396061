<template>
  <v-app-bar
    class="leaveBar mb-2"
    dense
    style="background-color: #f0f3f4"
    :style="{ border: '0' }"
  >
    <div class="text-lg-h5" style="font-weight: 300">
      Leave {{ isApprover ? "Approval" : "Application" }}
    </div>
    <v-spacer></v-spacer>
    <v-breadcrumbs
      customDivider
      divider="/"
      large
      :items="path"
    ></v-breadcrumbs>
  </v-app-bar>
</template>
<script>
export default {
  name: "AppBar",
  props: { isApprover: Boolean, path: Array },
  data: function () {
    return {};
  },
};
</script>
