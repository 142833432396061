import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCalendar } from 'vuetify/lib/components/VCalendar';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c(VRow,{staticClass:"fill-height"},[_c(VCol,[_c(VSheet,{attrs:{"height":"64"}},[_c(VToolbar,{attrs:{"flat":""}},[_c(VBtn,{staticClass:"mr-4",attrs:{"outlined":"","color":"grey darken-2"},on:{"click":_vm.setToday}},[_vm._v(" Today ")]),_c(VBtn,{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.prev}},[_c(VIcon,{attrs:{"small":""}},[_vm._v(" mdi-chevron-left ")])],1),_c(VBtn,{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.next}},[_c(VIcon,{attrs:{"small":""}},[_vm._v(" mdi-chevron-right ")])],1),(_vm.$refs.calendar)?_c(VToolbarTitle,[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")]):_vm._e(),_c(VSpacer),_c(VMenu,{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"outlined":"","color":"grey darken-2"}},'v-btn',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.typeToLabel[_vm.type]))]),_c(VIcon,{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c(VList,[_c(VListItem,{on:{"click":function($event){_vm.type = 'day'}}},[_c(VListItemTitle,[_vm._v("Day")])],1),_c(VListItem,{on:{"click":function($event){_vm.type = 'week'}}},[_c(VListItemTitle,[_vm._v("Week")])],1),_c(VListItem,{on:{"click":function($event){_vm.type = '4day'}}},[_c(VListItemTitle,[_vm._v("4 days")])],1)],1)],1)],1)],1),_c(VSheet,{attrs:{"height":"700"}},[_c(VCalendar,{ref:"calendar",staticClass:"leaveCalender",attrs:{"color":"primary","events":_vm.listFilterValue.length !== 0 ? _vm.applicationsFilter : _vm.applications,"event-color":_vm.getEventColor,"type":_vm.type,"show-interval-label":function () { return false; },"short-intervals":true,"interval-height":"0","interval-width":"0","start":_vm.listFilterValue.length !== 0
              ? _vm.applicationsFilter[0] !== undefined
                ? _vm.applicationsFilter[0].start
                : _vm.today
              : _vm.applications[0] !== undefined
              ? _vm.applications[0].start
              : _vm.today,"event-height":_vm.isApprover ? 70 : 60},on:{"click:more":_vm.viewDay,"click:date":_vm.viewDay,"click:event":_vm.showApplication},scopedSlots:_vm._u([{key:"event",fn:function(ref){
              var event = ref.event;
return [_c(VListItem,[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c(VListItemAvatar,_vm._g(_vm._b({staticStyle:{"color":"rgb(0, 68, 139)"},attrs:{"color":"blue lighten-4","size":"40"}},'v-list-item-avatar',attrs,false),on),[_vm._v(" "+_vm._s(event.leaveData.employeeInitials)+" ")])]}}],null,true)},[(event.leaveData.made_by !== undefined)?_c('span',[_vm._v(_vm._s(event.leaveData.fullName))]):_vm._e()]),_c(VListItemContent,[_c(VListItemTitle,{style:({ color: event.textColor })},[_vm._v(" "+_vm._s(event.leaveType)+" ")]),_c(VListItemSubtitle,{style:({ color: event.textColor })},[_c('span',{staticClass:"mr-3"},[_vm._v(_vm._s(event.name))]),_c('span',[_vm._v(" "+_vm._s(event.leaveData.days_applied + " Days")+" ")])])],1),_c(VListItemAction,[_c(VChip,{attrs:{"label":"","color":_vm.getColor(event.status).color,"small":""}},[_vm._v(" "+_vm._s(event.status)+" ")]),(event.approvalEntries.length > 0 && _vm.isApprover)?_c(VChip,{staticClass:"mt-1",attrs:{"label":"","color":_vm.getColor(event.status).color,"small":""}},[_vm._v(" "+_vm._s(event.status === "Released" || event.status === "Posted" ? "Approved" : "Approval Entry Open")+" ")]):_vm._e()],1)],1)]}},{key:"day-header",fn:function(ref){
              var weekday = ref.weekday;
return [(weekday === 0 || weekday === 6)?_c(VSheet,{staticClass:"daySheet",attrs:{"title":"this is a holiday","width":"100%","height":"100%","tile":""}}):_vm._e()]}}]),model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}}),_c(VMenu,{attrs:{"close-on-content-click":false,"activator":_vm.selectedElement,"offset-x":""},model:{value:(_vm.selectedOpen),callback:function ($$v) {_vm.selectedOpen=$$v},expression:"selectedOpen"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }