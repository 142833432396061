import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCol,{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('div',{staticClass:"text-center"},[_c(VDialog,{attrs:{"width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Preview ")]),(_vm.dialog)?_c(VCardText,{staticClass:"attachment"},[(_vm.base64Ext === 'pdf')?_c('div',_vm._l((_vm.pageCount(_vm.base64)),function(i){return _c('pdf',{key:i,attrs:{"page":i,"src":_vm.base64.length !== 0
                  ? ("data:" + _vm.mimeType + ";base64, " + _vm.base64)
                  : _vm.imageUrl}})}),1):(_vm.isImage)?_c('div',[_c(VImg,{staticClass:"pa-9",attrs:{"src":_vm.base64.length !== 0
                  ? ("data:" + _vm.mimeType + ";base64, " + _vm.base64)
                  : _vm.imageUrl}})],1):_c('div',[_c('div',{staticClass:"d-flex flex-column"},[_c(VBtn,{staticClass:"primary align-self-center mt-3",on:{"click":function($event){return _vm.download(_vm.base64)}}},[_vm._v(" Download File "),_c(VIcon,{staticClass:"mx-2"},[_vm._v("cloud_download")])],1)],1)])]):_vm._e(),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"error","text":""},on:{"click":function($event){_vm.setDialog = false}}},[_vm._v(" Close ")])],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }