<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <v-card>
      <v-form v-model="isValid" ref="Calcform">
        <v-card-title>Expense Receipt </v-card-title>
        <v-card-text>
          <div class="d-flex">
            <!-- <v-divider></v-divider> -->
            <v-card-subtitle class="mt-n3"
              >{{ imprestLine.expenseCode }}:
              {{ imprestLine.description }}</v-card-subtitle
            >
            <v-spacer></v-spacer>
            <v-chip
              small
              class="ma-2 font-weight-light"
              label
              color="light-blue lighten-5"
            >
              Balance:
              <v-divider vertical class="mx-3"></v-divider>
              {{ Currency ? Currency : "KES" }}
              {{ balance | currencyFormat }}
            </v-chip>
          </div>
          <v-divider />
          <div class="mx-5 mt-5">
            <!-- <span class="">Expenditure amount:</span> -->
            <v-text-field
              v-model.number="formData.amount"
              label="Expenditure Amount"
              type="number"
              min="0"
              step="1"
              :rules="rules.required"
              outlined
              dense
            />
            <!-- <h4 class="">Description of Receipt:</h4> -->
            <v-text-field
              v-model="formData.description"
              label="Receipt Number"
              :rules="rules.required"
              min="0"
              step="1"
              outlined
              dense
            />
            <v-file-input
              accept="image/jpeg,image/png,application/pdf"
              @change="uploadFile"
              :rules="rules.required"
              prepend-icon=""
              append-icon="mdi-camera"
              dense
              outlined
              label="Upload Document"
            />
          </div>
          <!-- <v-divider></v-divider> -->
        </v-card-text>
        <v-card-actions class="pb-7 mr-6 mt-n3">
          <v-spacer />
          <v-btn class="mx-3" v-on:click="setDialog = false" color="error" text>
            Cancel
          </v-btn>
          <v-btn @click="SaveImage()" color="primary"> Submit </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import helpers from "@kinetics254/cassandra-base/utilities/helpers/helper";
export default {
  name: "CalcImprest",
  props: {
    imprestLine: Object,
    dialog: Boolean,
    Currency: String,
  },

  data: function () {
    return {
      isValid: false,
      formData: {
        attachment: "",
        description: "",
        amount: null,
      },
    };
  },

  computed: {
    setDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("close-dialog", val);
      },
    },
    balance() {
      return this.imprestLine.balance - this.formData.amount || 0;
    },
    rules() {
      return {
        required: [(v) => !!v || "field is required"],
      };
    },
  },

  methods: {
    uploadFile: async function (value) {
      if (value) {
        this.formData.attachment = await helpers.toBase64(value);
        this.formData.attachment = this.formData.attachment.split(",")[1];
      }
    },
    SaveImage() {
      if (!this.isValid) {
        this.$refs.Calcform.validate();
      } else {
        this.formData.DetailedLineMgtLineNo = this.imprestLine.lineNo;
        this.formData.DetailedLineMgtDocNo = this.imprestLine.documentNo;
        this.formData.DetailedLineMgtDocType = this.imprestLine.documentType;
        this.$store.dispatch("imprest/SaveImage", {
          ...this.formData,
        });
        this.$refs.Calcform.reset();
        this.setDialog = false;
      }
    },
  },
};
</script>

<style scoped></style>
