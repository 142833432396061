import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VDialog,{attrs:{"width":"40%","persistent":""},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c(VCard,{staticClass:"pb-4"},[_c(VCardTitle,[_vm._v("Contract Rejection Modal")]),_c(VCardText,[_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.sendFoRejection.apply(null, arguments)}},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},_vm._l((_vm.rejectModal),function(field,i){return _c(VTextarea,{key:i,staticClass:"text-h6",attrs:{"label":field.label,"outlined":"","rows":5,"rules":[_vm.dataRules.required]},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})}),1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{ref:"submitBtn",attrs:{"color":"primary"},on:{"click":_vm.sendFoRejection}},[_vm._v("Submit")]),_c(VBtn,{on:{"click":_vm.closeModal}},[_vm._v("Close")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }