const light = [
  "blue lighten-5",
  "green lighten-5",
  "red lighten-5",
  "orange lighten-5",
  "light-blue lighten-5",
  "cyan lighten-5",
  "pink lighten-5",
  "teal lighten-5",
  "orange lighten-5",
  "deep-orange lighten-5",
];

const dark = [
  "#0D47A1",
  "#1B5E20",
  "#B71C1C",
  "#E65100",
  "#01579B",
  "#006064",
  "#880E4F",
  "#004D40",
  "#E65100",
  "#BF360C",
];

export default [{ dark, light }];
