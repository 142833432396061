import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"display":"block"}},[_c(VList,{attrs:{"dense":""}},[_c(VListItem,{staticClass:"top_list_item"},[_c(VListItemAvatar,{class:_vm.mini ? 'ml-n2' : '',attrs:{"color":"blue lighten-4","size":"40"}},[_c(VImg,{attrs:{"src":("data:application/jpg;base64," + (_vm.company.base64Logo))}})],1),_c(VListItemContent,[_c(VListItemTitle,{staticStyle:{"color":"#ffffff"}},[_vm._v(_vm._s(_vm.company.displayName))]),(_vm.userData.is_admin)?_c(VListItemSubtitle,{staticStyle:{"color":"#ffffff"}},[_vm._v(" "+_vm._s(("" + (_vm.userData.email)))+" ")]):_c(VListItemSubtitle,{staticStyle:{"color":"#ffffff"}},[_vm._v(" "+_vm._s(("" + (_vm.userProfile.email)))+" ")])],1)],1)],1)],1),(!_vm.mini)?_c(VDivider,{staticClass:"mx-3"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }