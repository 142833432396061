<template>
  <v-container>
    <v-card class="my-5 elevation-0" height="100%">
      <v-card-title>
        <v-btn text small @click="$router.back()">
          <v-icon left> mdi-arrow-left </v-icon>
          Back
        </v-btn>
        <v-spacer />
        <v-btn small color="primary" @click="downloadLink(leaveAttachment[0])">
          <v-icon left color="white"> mdi-download </v-icon>
          Download
        </v-btn>
      </v-card-title>
      <v-divider />

      <v-row>
        <v-col cols="8" offset="2">
          <v-card-text class="elevation-1 my-1">
            <div v-if="base64Ext === 'pdf'">
              <pdf
                v-for="i in pageCount(leaveAttachment[0])"
                :key="i"
                :page="i"
                :src="`data:${mimeType};base64, ${leaveAttachment[0]}`"
                class=""
              ></pdf>
            </div>
            <div v-else-if="isImage">
              <v-img
                :src="`data:${mimeType};base64, ${leaveAttachment[0]}`"
                class="pa-9"
              />
            </div>
            <div v-else>
              <div class="d-flex flex-column">
                <v-btn
                  class="primary align-self-center mt-3"
                  @click="downloadLink(leaveAttachment[0])"
                >
                  Download File
                  <v-icon class="mx-2">cloud_download</v-icon>
                </v-btn>
              </div>
            </div>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import pdf from "vue-pdf";
import FileMixin from "@kinetics254/cassandra-base/mixins/FileMixin";
import helpers from "@kinetics254/cassandra-base/utilities/helpers/helper";

export default {
  name: "LeaveAttachmentPreview",
  mixins: [FileMixin],
  components: { pdf },
  data: function () {
    return {
      numPages: undefined,
      base64Ext: "",
      mimeType: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("leave/getLeaveAttachment", {
        data: { application_code: v.$route.params.application_code },
      });
      // v.getMimeType(v.leaveAttachment)
    });
  },
  computed: {
    leaveAttachment() {
      return this.$store.getters["leave/leaveGetter"]("leaveAttachment");
    },
    isImage() {
      return /png|jpeg|xml|gif|svg/.test(this.base64Ext);
    },
  },
  methods: {
    getPages: function (document) {
      if (document.length !== 0) {
        document = pdf.createLoadingTask(document);
        document.promise.then((pdf) => {
          this.numPages = pdf._pdfInfo.numPages;
        });
        return this.numPages;
      }
    },
    getMimeType(base64) {
      helpers.getFileType(base64).then((res) => {
        this.mimeType = res.mime;
        this.base64Ext = res.ext;
      });
    },
  },
  watch: {
    leaveAttachment: {
      handler(n, o) {
        if (n[0] !== "") {
          this.getMimeType(n[0]);
        }
      },
    },
  },
};
</script>
