import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VAppBar,{staticClass:"leaveBar",staticStyle:{"background-color":"#f0f3f4"},style:({ border: '0' }),attrs:{"dense":""}},[_c(VToolbarTitle,{staticClass:"text-h5 font-weight-bolder"},[_vm._v(" Payslip ")]),_c(VSpacer),_c(VBreadcrumbs,{attrs:{"items":_vm.crumbs},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c(VIcon,[_vm._v("mdi-chevron-right")])]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VBreadcrumbsItem,{staticClass:"text-capitalize",attrs:{"href":item.href,"disabled":""}},[_vm._v(" "+_vm._s(item.text)+" ")])]}}])})],1),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }