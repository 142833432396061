export default [
  {
    order: 0,
    name: "Admin Settings",
    icon: "settings",
    to: "/admin/settings",
    enabled: true,
  },
  {
    order: 1,
    name: "User Settings",
    icon: "people",
    to: "/admin/user-settings",
    enabled: true,
  },
];
