import { PayslipRoutes, PayslipStore, PayslipLinks } from "./modules/payslip";

export default {
  install(Vue, options) {
    /* register store and routes */
    if (options.router) options.router.addRoute(...PayslipRoutes);
    if (options.store) options.store.registerModule("payslip", PayslipStore);

    options.store.commit("Dashboard/ADD_LINK", PayslipLinks);
  },
};
