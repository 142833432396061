import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"mx-auto",attrs:{"tile":""}},[_c(VBtn,{staticStyle:{"position":"absoulte","right":"1em"},style:({
        float: 'right',
        color: '#000',
        marginTop: '1%',
      }),attrs:{"icon":""},on:{"click":function($event){_vm.setDialog = false}}},[_c(VIcon,{attrs:{"size":"30"}},[_vm._v("mdi-close")])],1),_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.HandOver.type === "Accept" ? "Accept" : "Reject")+" ")]),_c(VCardText,[_c(VForm,{attrs:{"rel":"acceptForm"},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[(_vm.HandOver.type === 'Accept')?_c('div',[_vm._v(" Confrim Acceptance of HandOver Request ")]):_c('div',[_c('div',[_vm._v("Confrim Reject")]),_c(VTextarea,{staticClass:"mt-1 mx-4 leaveInputs",attrs:{"placeholder":"Comments","rows":1,"clearable":"","filled":"","rules":[_vm.rules.common.required]},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(" comment ")])]},proxy:true}]),model:{value:(_vm.handoverComment),callback:function ($$v) {_vm.handoverComment=$$v},expression:"handoverComment"}})],1)])],1),_c(VCardActions,[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VBtn,{staticClass:"btnActions mx-3",staticStyle:{"float":"right"},attrs:{"color":"red","block":"","text":""},on:{"click":function($event){_vm.setDialog = false}}},[_vm._v(" Cancel "),_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[(_vm.HandOver.type !== 'Accept')?_c('div',[_c(VBtn,{staticClass:"btnActions",staticStyle:{"color":"#fff"},attrs:{"color":"red","block":"","disabled":!_vm.isValid},on:{"click":function($event){$event.preventDefault();return _vm.reject(false)}}},[_vm._v(" Reject "),_c(VIcon,{staticClass:"mx-3"},[_vm._v("thumb_down_alt")])],1)],1):_c('div',[_c(VBtn,{staticClass:"btnActions",staticStyle:{"color":"#fff"},attrs:{"color":"success","block":""},on:{"click":function($event){$event.preventDefault();return _vm.approve(true)}}},[_vm._v(" Accept "),_c(VIcon,{staticClass:"mx-3"},[_vm._v("thumb_up_alt")])],1)],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }