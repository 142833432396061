<template>
  <v-container>
    <TrainingBreadcrumbs />
    <v-card class="mx-auto" elevation="0">
      <v-card-title>
        <div class="headline">Training Requests</div>
        <v-spacer />
        <v-btn class="primary" @click="trainingCard">
          <v-icon class="mx-2">mdi-plus</v-icon>
          Add Training Request
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-tabs v-model="tab" grow>
          <v-tabs-slider color="grey"></v-tabs-slider>
          <v-tab v-if="!isSupervisor">My Requests</v-tab>
          <v-tab>{{ isSupervisor ? "All" : "Existing" }} Requests</v-tab>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-container>
                <v-data-table
                  :headers="headers"
                  :items="!isSupervisor ? trainings : allTrainings"
                  :items-per-page="5"
                  class="elevation-0"
                  :footer-props="{
                    showFirstLastPage: true,
                    'items-per-page-text': 'Requests per page',
                  }"
                >
                  <template v-slot:item.description="{ item }">
                    <span v-if="item.description">
                      {{
                        item.description.length > 30
                          ? `${item.description.slice(0, 40)}...`
                          : item.description
                      }}
                    </span>
                  </template>
                  <template v-slot:item.submittedBy="{ item }">
                    {{ `${item.submittedBy}:&thinsp;${item.submittedName}` }}
                  </template>
                  <template v-slot:item.programmes="{ item }">
                    {{ item.trainingNeedLines.length }}
                  </template>
                  <template v-slot:item.status="{ item }">
                    <v-chip small label :color="getColor(item.status).color">
                      <div style="color: #fff">{{ item.status }}</div>
                    </v-chip>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <div v-if="item.status === 'Open'">
                      <!-- edit -->
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            :to="{
                              name: 'TrainingCard',
                              params: { code: encodeRoute(item.no) },
                            }"
                            class="mx-1"
                            color="teal lighten-4"
                            label
                            small
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon color="teal darken-4" small>
                              mdi-pencil
                            </v-icon>
                          </v-chip>
                        </template>
                        <span>edit</span>
                      </v-tooltip>
                      <!-- delete -->
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            @click="deleteTraining(item)"
                            class="mx-1"
                            color="error lighten-4"
                            label
                            small
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon color="error darken-4" small>
                              mdi-delete
                            </v-icon>
                          </v-chip>
                        </template>
                        <span>delete</span>
                      </v-tooltip>
                      <!-- send for approval -->
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            @click="sendForApproval(item)"
                            class="mx-1"
                            color="blue lighten-4"
                            label
                            small
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon color="blue darken-4" small>
                              mdi-send
                            </v-icon>
                          </v-chip>
                        </template>
                        <span>send for approval</span>
                      </v-tooltip>
                    </div>
                    <div v-else>
                      <!-- view -->
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            :to="{
                              name: 'TrainingCard',
                              params: { code: encodeRoute(item.no) },
                            }"
                            class="mx-1"
                            color="blue lighten-4"
                            label
                            small
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon color="blue darken-4" small>
                              mdi-eye
                            </v-icon>
                          </v-chip>
                        </template>
                        <span>view</span>
                      </v-tooltip>
                      <!-- cancel approval request -->
                      <v-tooltip
                        bottom
                        v-if="item.status === 'Pending Approval'"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            @click="cancelApprovalRequest(item)"
                            class="mx-1"
                            color="orange lighten-4"
                            label
                            small
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon color="orange darken-4" small>
                              cancel_schedule_send
                            </v-icon>
                          </v-chip>
                        </template>
                        <span>cancel approval request</span>
                      </v-tooltip>
                    </div>
                  </template>
                </v-data-table>
              </v-container>
            </v-tab-item>

            <v-tab-item v-if="!isSupervisor">
              <v-container>
                <v-data-table
                  :headers="headers"
                  :items="allTrainings"
                  :items-per-page="5"
                  dense
                  class="elevation-0"
                  :footer-props="{
                    showFirstLastPage: true,
                    'items-per-page-text': 'Requests per page',
                  }"
                >
                  <template v-slot:item.description="{ item }">
                    <span v-if="item.description">
                      {{
                        item.description.length > 30
                          ? `${item.description.slice(0, 40)}...`
                          : item.description
                      }}
                    </span>
                  </template>
                  <template v-slot:item.submittedBy="{ item }">
                    {{ `${item.submittedBy}:&thinsp;${item.submittedName}` }}
                  </template>
                  <template v-slot:item.programmes="{ item }">
                    {{ item.trainingNeedLines.length }}
                  </template>
                  <template v-slot:item.status="{ item }">
                    <v-chip small label :color="getColor(item.status).color">
                      {{ item.status }}
                    </v-chip>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <div class="d-flex">
                      <!-- view -->
                      <v-chip
                        class="mr-2"
                        color="teal lighten-4"
                        small
                        label
                        v-if="item.status !== 'Open'"
                        @click="
                          $router.push({
                            name: 'TrainingCard',
                            params: { code: encodeRoute(item.no) },
                          })
                        "
                      >
                        <span class="text--teal-darken-2 text-uppercase">
                          view
                        </span>
                      </v-chip>
                      <!-- edit -->
                      <v-chip
                        v-if="item.status === 'Open'"
                        class="mr-2"
                        color="blue lighten-4"
                        small
                        label
                        @click="
                          $router.push({
                            name: 'TrainingCard',
                            params: { code: encodeRoute(item.no) },
                          })
                        "
                      >
                        <span class="blue--text-darken-2 text-uppercase">
                          edit
                        </span>
                      </v-chip>
                    </div>
                  </template>
                </v-data-table>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import TrainingBreadcrumbs from "./TrainingBreadcrumbs";
import AuthService from "@/packages/cassandra-base/modules/auth/AuthService";
import helpers from "@kinetics254/cassandra-base/utilities/helpers/helper";
import RouterMixin from "../../../router/RouterMixin.js";

export default {
  name: "TrainingList",
  components: { TrainingBreadcrumbs },
  mixins: [RouterMixin],
  data: function () {
    return {
      tab: 0,
    };
  },

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Training/getTrainings");
      v.$store.dispatch("Training/getAllTrainings");
    });
  },
  computed: {
    isSupervisor() {
      return AuthService.user.profile.isHOD;
    },
    trainings: function () {
      return this.$store.getters["Training/trainingGetters"](
        "trainings"
      ).reverse();
    },
    headers: function () {
      return [
        {
          text: "Training",
          value: "no",
          align: "left",
          sortable: false,
        },
        { text: "Description", value: "description" },
        { text: "Submitted By", value: "submittedBy" },
        { text: "Programmes", value: "programmes", align: "center" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
    user() {
      return AuthService.user.profile;
    },
    allTrainings: function () {
      return this.$store.getters["Training/trainingGetters"]("allTrainings")
        .filter((item) => {
          return !this.isSupervisor
            ? item.submittedBy !== this.user.number
            : item;
        })
        .reverse();
    },
  },
  methods: {
    getColor(status) {
      return helpers.getColor(status);
    },
    trainingCard: function () {
      this.$router.push({ name: "TrainingCard" });
    },
    deleteTraining: function (data) {
      const params = {
        title: ` Training request ${data.no}`,
        text: "Are you sure you want to delete this training request?",
        onConfirm: () => {
          this.$store.dispatch("Training/deleteTraining", data);
        },
      };
      this.$confirm.show(params);
    },
    sendForApproval: function (data) {
      const no = {
        docNo: data.no,
      };
      this.$store.dispatch("Training/sendForApproval", no);
    },
    cancelApprovalRequest: function (data) {
      const no = {
        docNo: data.no,
      };
      this.$store.dispatch("Training/cancelApproval", no);
    },
  },
};
</script>
