import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFlex } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VCard,[_c(VCardTitle,[_vm._v("My Contracts")]),_c(VFlex,{attrs:{"xs12":""}},[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.contracts,"items-per-page":5},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.No))]),_c('td',[_vm._v(_vm._s(item.employeeName))]),_c('td',[_vm._v(_vm._s(item.employeeNo))]),_c('td',[_vm._v(_vm._s(item.status))]),(item.status !== 'Declined')?_c('td',[_c(VBtn,{staticClass:"mr-6 white",attrs:{"color":"primary"},on:{"click":function($event){return _vm.getContract(item.No)}}},[_vm._v("View")]),(item.status === 'Pending')?_c(VBtn,{attrs:{"color":"orange"},on:{"click":function($event){return _vm.getContractLetter(item.No)}}},[_vm._v("Consent")]):_vm._e()],1):_vm._e()])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }