<template>
  <div>
    <v-menu
      ref="menu"
      v-model="endMenu"
      :close-on-content-click="true"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="endDateLocal"
          placeholder="End Date"
          persistent-placeholder
          label="End Date"
          dense
          clearable
          readonly
          v-bind="attrs"
          v-on="on"
          filled
          class="mt-1 leaveInputs"
          :rules="rules"
        >
          <template v-slot:prepend-inner>
            <v-icon color="primary"> mdi-calendar </v-icon>
          </template>
        </v-text-field>
      </template>
      <v-date-picker
        v-model="endDateLocal"
        no-title
        scrollable
        :min="startDate"
        :max="
          leavePeriod[0] !== undefined
            ? leavePeriod[0].current_period_end_date
            : null
        "
        @input="endMenu = false"
      >
      </v-date-picker>
    </v-menu>
  </div>
</template>
<script>
export default {
  name: "EndDate",
  props: {
    endDate: String,
    leavePeriod: Array,
    rules: Array,
    startDate: String,
    displayDate: String,
  },
  data: () => ({
    end_date: false,
    endMenu: false,
  }),
  computed: {
    endDateLocal: {
      get() {
        return this.displayDate;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {},
};
</script>
