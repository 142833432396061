<template>
  <v-app id="inspire" v-cloak>
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-dialog
      class="loadDialog"
      persistent
      :no-click-animation="true"
      :value="loading"
      overlay-opacity="0"
      width="95"
      origin="center center"
    >
      <Spinner class="spinner" />
    </v-dialog>
    <v-dialog
      class="loadDialog"
      persistent
      :no-click-animation="true"
      :value="switching"
      overlay-opacity="0"
      width="150"
      origin="center center"
    >
      <Switcher />
    </v-dialog>
  </v-app>
</template>

<script>
import Spinner from "@kinetics254/cassandra-base/plugins/loader/views/Spinner";
import Switcher from "./packages/cassandra-base/plugins/loader/views/SwitchLoader";
export default {
  name: "App",
  components: {
    Spinner,
    Switcher,
  },
  data: () => ({
    //
  }),
  computed: {
    loading: function () {
      return this.$store.state.loader.loading;
    },
    switching: function () {
      return this.$store.getters["loader/switch"];
    },
  },
};
</script>
