<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="10" offset-md="1">
        <v-card class="my-5" v-if="payslip">
          <v-card-title>
            <v-btn text small @click="$router.back()">
              <v-icon left>mdi-arrow-left</v-icon>
              Back
            </v-btn>
            <v-spacer />
            <v-btn
              :disabled="!payslip.status"
              small
              class="mr-2"
              @click="downloadFile(payslip.data)"
            >
              <v-icon left>mdi-download</v-icon>
              Download
            </v-btn>
          </v-card-title>

          <v-card-text
            v-if="!payslip.status"
            class="elevation-1 mb-1 pa-10 text-center"
          >
            {{ payslip.message }}
          </v-card-text>

          <v-card-text
            class="elevation-1 mb-1"
            v-for="i in pageCount(payslip.data)"
            :key="i"
            v-else
          >
            <pdf
              style="width: 100%"
              :page="i"
              :src="`data:application/pdf;base64,${payslip.data}`"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RouterMixin from "@kinetics254/cassandra-base/mixins/RouterMixin";
import fileType from "file-type";
import pdf from "vue-pdf";

export default {
  name: "PayslipView",
  mixins: [RouterMixin],
  components: { pdf },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch(
        "payslip/getPayslip",
        v.decodeRoute(v.$route.params.code)
      );
    });
  },

  computed: {
    payslip() {
      return this.$store.getters["payslip/payslip"];
    },
  },

  methods: {
    downloadFile: async function (
      attachment,
      filename = `${this.decodeRoute(this.$route.params.code)} Payslip.pdf`
    ) {
      const myBuffer = Buffer.from(attachment, "base64");
      await fileType.fromBuffer(myBuffer).then((res) => {
        const path = `data:${res.mime})};base64,${attachment}`;
        const element = document.createElement("a");
        element.setAttribute("href", path);
        element.setAttribute("download", `${filename}`);
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      });
    },

    pageCount: function (value) {
      const bin = atob(value);
      return bin.match(/\/Type\s*\/Page\b/g).length;
    },
  },
};
</script>

<style scoped></style>
