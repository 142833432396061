<template>
  <v-combobox
    v-model="model"
    :items="items"
    :search-input.sync="search"
    outlined
    color="primary"
    hide-no-data
    hide-selected
    item-text="display_name"
    item-value="place_id"
    placeholder="Start typing to Search"
    prepend-inner-icon="place"
    dense
    @update:search-input="onInput"
    :value="value"
    return-object
  >
    <template v-slot:label>
      <label v-if="!routeParams"
        >{{ `${type === 1 ? "From" : "To"}`
        }}<span class="required">*</span></label
      >
    </template>
  </v-combobox>
</template>

<script>
// WORK IN PROGRESS
export default {
  name: "LocationAutoComplete",
  props: {
    type: Number,
    routeParams: String,
    value: { type: String, default: "" },
  },
  data() {
    return {
      descriptionLimit: 60,
      entries: [{ display_name: this.value }],
      isLoading: false,
      model: { display_name: this.value },
      search: null,
      showValue: this.value.length > 0,
    };
  },
  computed: {
    items() {
      if (this.search === null) {
        return [];
      } else {
        return this.entries.map((entry) => {
          const location =
            entry.display_name.length > this.descriptionLimit
              ? entry.display_name.slice(0, this.descriptionLimit) + "..."
              : entry.display_name;
          return Object.assign({}, entry, { location });
        });
      }
    },
  },
  watch: {
    search(val) {
      // Items have already been loaded
      // if (this.entries.length > 1) return
      if (this.showValue) return;
      // Items have already been requested
      try {
        // Lazily load input items
        fetch(
          `https://nominatim.openstreetmap.org/search/${this.search}?format=json&address`
        )
          .then((res) => res.json())
          .then((res) => {
            this.count = res.length;
            this.entries = res;
          });
      } catch (err) {
        console.log(err);
      }
    },

    model() {
      if (this.model.display_name) {
        const location = this.model.display_name.split(",");
        const name =
          location[0].length > 15
            ? location[0]
            : `${location[0]},${location[1]}`;
        this.$emit("update", { val: name, type: this.type });
      } else {
        this.$emit("update", { val: `${this.search}`, type: this.type });
      }
    },
  },
  methods: {
    onInput(val) {
      if (val !== null) {
        if (val.length !== this.value.length) {
          val === null ? (this.isLoading = true) : (this.isLoading = false);
          this.showValue = false;
        }
      }
    },
  },
};
</script>
<style scoped>
.required {
  color: red;
}
</style>
