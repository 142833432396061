export default {
  disciplinary: (params) => `/disciplinary?${getParams(params)}`,
  updateDisciplinary: "/disciplinary/update",
  deleteDisciplinary: "/disciplinary/delete",
  deleteDisciplinaryLine: "/disciplinary/delete-line",
  disciplinaryCategories: "/disciplinary/disciplinary-categories",
  addDisciplinary: "/disciplinary",
  respondDisciplinary: "/disciplinary/respond",
};

function getParams(params) {
  return Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&");
}
