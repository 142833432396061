import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":"","grid-list-xl":""}},[_c(VAppBar,{attrs:{"dense":"","elevation":"0","color":"transparent"}},[_c('div',{staticClass:"text-lg-h5 ml-n4"},[_vm._v("My Statistics")]),_c(VSpacer),_c(VChip,{staticClass:"primary lighten-4 primary--text text--darken-4"},[_vm._v(" "+_vm._s(_vm.userData.employee)+" ")])],1),_c('div',[_c('StatsView',{attrs:{"data":_vm.allStats}})],1),_c(VRow,[_c(VCol,{attrs:{"cols":"4"}},[_c(VCard,{staticClass:"mt-4"},[(_vm.doughnutData)?_c('DoughnutChart',{attrs:{"doughnutData":_vm.doughnutData}}):_vm._e()],1)],1),_c(VCol,{attrs:{"cols":"8"}},[_c('div',[_c(VCard,{staticClass:"mt-4"},[_c('MultiDataBarchart',{attrs:{"multiData":_vm.MultiData}})],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }