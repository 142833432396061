<template>
  <v-container>
    <v-dialog
      content-class="imageDialog"
      light
      overlay-opacity="0.5"
      v-model="OpenDialog"
      width="80%"
    >
      <v-btn
        icon
        :style="{
          float: 'right',
          color: '#000',
          marginTop: '-1%',
        }"
        style="position: absolute; right: 5em"
        @click="OpenDialog = false"
      >
        <v-icon size="50">mdi-close</v-icon>
      </v-btn>
      <v-img
        style="max-height: 90%; max-width: 100%"
        :src="`data:application/jpg;base64,${Image}`"
      ></v-img>
    </v-dialog>
  </v-container>
</template>
<style>
.imageDialog.v-dialog {
  border-radius: 0;
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  z-index: inherit;
  box-shadow: 0 11px 15px -7px rgb(0 0 0 / 0%), 0 24px 38px 3px rgb(0 0 0 / 0%),
    0 9px 46px 8px rgb(0 0 0 / 0%);
}
</style>
<script>
export default {
  name: "ViewApplication",
  props: { Image: String, Status: Boolean, ShowDialog: Boolean },
  data: () => ({}),
  computed: {
    OpenDialog: {
      get() {
        return this.ShowDialog;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
