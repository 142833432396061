<template>
  <v-container>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="justify-center grey lighten-2">
          Leave Allowance
        </v-card-title>

        <v-card-text class="cardfont text-center font-weight-medium darken-2">
          Do you want to apply for Leave allowance?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="error"
            text
            v-on:click="setDialog = false"
            @click="SaveApplication"
          >
            No
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="OpenAllowanceForm">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div>
      <v-dialog v-model="allowancedialog" width="800" persistent>
        <allowance-form
          :leaveData="this.leaveData"
          :allowancedialog="allowancedialog"
          :passmodal="passmodal"
          v-on:close-dialog="closeDialog"
        />
      </v-dialog>
    </div>
  </v-container>
</template>
<style>
@import "../styles.css";
</style>
<script>
/* eslint-disable camelcase */
import AllowanceForm from "../../allowance/components/AllowanceForm.vue";
import { SetupMixin } from "../../../mixins";
import helpers from "@kinetics254/cassandra-base/utilities/helpers/helper";

export default {
  name: "confirmationdialog",
  props: {
    leaveData: Object,
    dialog: Boolean,
  },
  components: { AllowanceForm },
  mixins: [SetupMixin],
  data: function () {
    return {
      allowancedialog: false,
      passmodal: false,
      toggle: false,
    };
  },
  computed: {
    setDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("close-dialog", val);
      },
    },
    leavedataforallowance() {
      return this.leaveData;
    },
  },
  methods: {
    SaveApplication: function (flag) {
      this.$store.dispatch(
        "leave/submitApplication",
        helpers.prepareFormData(this.leaveData)
      );
    },
    OpenAllowanceForm: function (flag) {
      this.setDialog = false;
      this.allowancedialog = true;
      this.passmodal = true;
      this.$store.dispatch("allowance/GetEmployeeAllowance");
    },

    closeDialog: function (val) {
      this.allowancedialog = val;
    },
  },
};
</script>
<style scoped>
.cardfont {
  font-size: 17px;
}
</style>
