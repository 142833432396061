<template>
  <v-dialog v-model="setDialog" max-width="500px" persistent>
    <v-card tile>
      <v-card-title color="primary"> Edit Dimensions </v-card-title>

      <v-card-text class="mt-3">
        <v-form v-model="isValid" ref="DimensionsForm">
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="speedKey"
                    :items="speedKeys"
                    :item-text="(item) => item.speedkeyCode"
                    :item-value="(item) => item.speedkeyCode"
                    v-if="speedKeys.length > 0"
                    label="Select Speed Key"
                    :disabled="readOnlyCheck"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="bill.fundNo"
                    :items="funds"
                    :item-text="(item) => item.no + ' - ' + item.name"
                    :item-value="(item) => item.no"
                    v-if="funds.length > 0"
                    label="Select Fund"
                    :disabled="readOnlyCheck"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="bill.globalDimension1Code"
                    :items="dimension1"
                    :item-text="(item) => item.code + ' - ' + item.name"
                    :item-value="(item) => item.code"
                    v-if="dimension1.length > 0"
                    :label="`${dimension1[0]['dimensionCode']}`"
                    :disabled="readOnlyCheck"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="bill.globalDimension2Code"
                    :items="dimension2"
                    :item-text="(item) => item.code + ' - ' + item.name"
                    :item-value="(item) => item.code"
                    v-if="dimension2.length > 0"
                    :label="`${dimension2[0]['dimensionCode']}`"
                    :disabled="readOnlyCheck"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="bill.globalDimension3Code"
                    :items="dimension3"
                    :item-text="(item) => item.code + ' - ' + item.name"
                    :item-value="(item) => item.code"
                    v-if="dimension3.length > 0"
                    :label="`${dimension3[0]['dimensionCode']}`"
                    :disabled="readOnlyCheck"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="bill.globalDimension4Code"
                    :items="dimension4"
                    :item-text="(item) => item.code + ' - ' + item.name"
                    :item-value="(item) => item.code"
                    v-if="dimension4.length > 0"
                    :label="`${dimension4[0]['dimensionCode']}`"
                    :disabled="readOnlyCheck"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="bill.globalDimension5Code"
                    :items="dimension5"
                    :item-text="(item) => item.code + ' - ' + item.name"
                    :item-value="(item) => item.code"
                    v-if="dimension5.length > 0"
                    :label="`Select ${dimension5[0]['dimensionCode']}`"
                    :disabled="readOnlyCheck"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="bill.globalDimension6Code"
                    :items="dimension6"
                    :item-text="(item) => item.code + ' - ' + item.name"
                    :item-value="(item) => item.code"
                    v-if="dimension6.length > 0"
                    :label="`Select ${dimension6[0]['dimensionCode']}`"
                    :disabled="readOnlyCheck"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="bill.globalDimension7Code"
                    :items="dimension7"
                    :item-text="(item) => item.code + ' - ' + item.name"
                    :item-value="(item) => item.code"
                    v-if="dimension7.length > 0"
                    :label="`Select ${dimension7[0]['dimensionCode']}`"
                    :disabled="readOnlyCheck"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="bill.globalDimension8Code"
                    :items="dimension8"
                    :item-text="(item) => item.code + ' - ' + item.name"
                    :item-value="(item) => item.code"
                    v-if="dimension8.length > 0"
                    :label="`Select ${dimension8[0]['dimensionCode']}`"
                    :disabled="readOnlyCheck"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text v-on:click="setDialog = false" color="error">
          <span v-if="!readOnlyCheck">Cancel</span>
          <span v-else>Close</span>
        </v-btn>
        <v-btn color="primary" @click="editDimensions()" v-if="!readOnlyCheck">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ReqMixin from "../ReqMixin.js";
export default {
  name: "DimensionsDialog.vue",
  mixins: [ReqMixin],
  props: {
    itemDialog: Boolean,
    selectedBillingItem: Object,
    formData: Object,
    isEdit: Boolean,
    documentType: String,
    requisition: Object,
    dimensionValues: {
      type: Array,
      default: () => [],
    },
    readOnlyCheck: Boolean,
  },
  data() {
    return {
      isValid: false,
      panel: [0],
    };
  },
  computed: {
    speedKey: {
      get() {
        return this.bill.dimensionSpeedkeyCode;
      },
      set(val) {
        const speedKey = this.speedKeys
          .filter((el) => el.speedkeyCode === val)
          .shift();
        this.bill.dimensionSpeedkeyCode = val;
        this.bill.fundNo = speedKey.fundNo;
        this.bill.globalDimension1Code = speedKey.globalDimension1Code;
        this.bill.globalDimension2Code = speedKey.globalDimension2Code;
        this.bill.globalDimension3Code = speedKey.globalDimension3Code;
        this.bill.globalDimension4Code = speedKey.globalDimension4Code;
        this.bill.globalDimension5Code = speedKey.globalDimension5Code;
        this.bill.globalDimension6Code = speedKey.globalDimension6Code;
        this.bill.globalDimension7Code = speedKey.globalDimension7Code;
        this.bill.globalDimension8Code = speedKey.globalDimension8Code;
      },
    },
    setDialog: {
      get() {
        return this.itemDialog;
      },
      set(val) {
        this.$emit("close-dialog", val);
      },
    },
    bill: {
      get() {
        return this.selectedBillingItem;
      },
      set(val) {
        return val;
      },
    },
    isStoreRequisition() {
      return (
        this.documentType === "Store Requisition" ||
        this.requisition.documentType === "Store Requisition"
      );
    },
  },
  methods: {
    editDimensions: function () {
      this.$emit("updateDimensions", this.bill);
      this.setDialog = false;
    },
  },
};
</script>
<style scoped>
@import "../styles/style.css";
</style>
