<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <v-card class="mx-auto" tile>
      <v-btn
        icon
        :style="{
          float: 'right',
          color: '#000',
          marginTop: '1%',
        }"
        style="position: absoulte; right: 1em"
        v-on:click="setDialog = false"
      >
        <v-icon size="30">mdi-close</v-icon>
      </v-btn>
      <v-card-title>
        {{ HandOver.type === "Accept" ? "Accept" : "Reject" }}
        <!-- appcode -->
      </v-card-title>
      <v-card-text>
        <v-form rel="acceptForm" v-model="isValid">
          <div v-if="HandOver.type === 'Accept'">
            Confrim Acceptance of HandOver Request
          </div>
          <div v-else>
            <div>Confrim Reject</div>
            <v-textarea
              placeholder="Comments"
              :rows="1"
              v-model="handoverComment"
              clearable
              class="mt-1 mx-4 leaveInputs"
              filled
              :rules="[rules.common.required]"
            >
              <template v-slot:prepend-inner>
                <v-icon color="primary"> comment </v-icon>
              </template>
            </v-textarea>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row no-gutters>
          <v-col cols="12" md="6">
            <v-btn
              color="red"
              block
              text
              class="btnActions mx-3"
              style="float: right"
              v-on:click="setDialog = false"
            >
              Cancel
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" md="6">
            <div v-if="HandOver.type !== 'Accept'">
              <v-btn
                color="red"
                block
                class="btnActions"
                style="color: #fff"
                :disabled="!isValid"
                @click.prevent="reject(false)"
              >
                Reject
                <v-icon class="mx-3">thumb_down_alt</v-icon>
              </v-btn>
            </div>
            <div v-else>
              <v-btn
                color="success"
                block
                class="btnActions"
                style="color: #fff"
                @click.prevent="approve(true)"
              >
                Accept
                <v-icon class="mx-3">thumb_up_alt</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
/* eslint-disable camelcase */
import helpers from "@kinetics254/cassandra-base/utilities/helpers/helper";
import { AuthService } from "@kinetics254/cassandra-base/modules/auth";

export default {
  name: "AcceptDialog",
  props: {
    HandOver: Object,
    dialog: Boolean,
  },

  data: function () {
    return {
      isValid: false,
      formData: {
        acceptHandover: "",
      },
      // isEdit: null,
      acceptHandover: "",
      handoverComment: "",

      rules: {
        common: {
          required: (v) => !!v || "Field is required.",
        },
      },
    };
  },

  computed: {
    setDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("close-dialog", val);
      },
    },
    user() {
      return AuthService.user;
    },
    HandoverNo() {
      const No = this.HandOver.data.handover
        ? this.HandOver.data.handover.find(
            (d) => d.handoverTo === this.user.profile.number
          ).no
        : {};
      return No;
    },
    empNo() {
      const No = this.HandOver.data.handover
        ? this.HandOver.data.handover.find(
            (d) => d.handoverTo === this.user.profile.number
          ).empNo
        : {};
      return No;
    },
  },
  methods: {
    approve: function (val) {
      const { no_days, acceptHandover, handoverComment } = this;

      /** submit application + submit application */
      const data = {
        days_applied: no_days,
        acceptHandover: acceptHandover,
        handoverComment: handoverComment,
        handoverNo: this.HandoverNo ? this.HandoverNo : "",
        senderNo: this.empNo ? this.empNo : "",
      };

      data.application_code = this.HandOver.data.application_code;
      data.days_applied = this.HandOver.data.days_applied;
      data.acceptHandover = this.acceptHandover = val;
      // console.log(val)
      this.$store.dispatch(
        "leave/acceptRejectHandover",
        helpers.prepareFormData(data)
      );
    },
    reject: function (val) {
      const { no_days, acceptHandover, handoverComment } = this;

      /** submit application + submit application */
      const data = {
        days_applied: no_days,
        acceptHandover: acceptHandover,
        handoverComment: handoverComment,
      };

      data.application_code = this.HandOver.application_code;
      data.days_applied = this.HandOver.days_applied;
      data.handoverComment = this.handoverComment;
      data.acceptHandover = this.acceptHandover = val;
      this.$store.dispatch(
        "leave/acceptRejectHandover",
        helpers.prepareFormData(data)
      );
    },
  },
};
</script>

<style scoped></style>
