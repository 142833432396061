<template>
  <v-container>
    <StatsView :data="storageStats">
      <template #stats>
        <v-layout wrap class="mt-n4">
          <v-flex v-for="(item, i) in storageStats" :key="i" class="lg5-custom">
            <v-hover v-slot="{ hover }" open-delay="200">
              <v-card
                flat
                style="background-color: #ffffff"
                :elevation="hover ? 16 : 0"
                :class="{ 'on-hover': hover }"
                class="mx-2 mt-2"
              >
                <v-list>
                  <v-list-item class="stats">
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-lg-h5 stats-info font-weight-bold"
                        >{{ item.count }}</v-list-item-title
                      >
                      <v-list-item-subtitle class="stats-info">{{
                        item.name
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-avatar :color="item.avatarColor" size="53">
                        <v-icon x-large :color="item.iconColor">{{
                          item.icon
                        }}</v-icon>
                      </v-avatar>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-hover>
          </v-flex>
        </v-layout>
      </template>
    </StatsView>
    <v-row>
      <v-col cols="6">
        <v-card class="mt-4">
          <PieChart :pieData="pieData" />
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="mt-4">
          <BarChart :barData="barData" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import StatsView from "@kinetics254/cassandra-dashboards/Stats/StatsView.vue";
import PieChart from "@kinetics254/cassandra-dashboards/Charts/PieChart.vue";
import BarChart from "@kinetics254/cassandra-dashboards/Charts/BarChart.vue";
import helpers from "@kinetics254/cassandra-base/utilities/helpers/helper.js";

export default {
  name: "ReqDashboard",
  components: { StatsView, PieChart, BarChart },
  data: function () {
    return {
      months: [
        "Jan",
        "Feb",
        "March",
        "May",
        "Jun",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ],
    };
  },

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Requisition/getRequisitions");
    });
  },
  computed: {
    storageStats: function () {
      const Statistics =
        this.$store.getters["Requisition/reqGetters"]("requisitions");
      return [
        {
          count: Statistics ? Statistics.length : [],
          name: "Total Requisitions",
          avatarColor: "teal accent-2",
          iconColor: "teal darken-4",
          icon: "all_inbox",
          background: "coral",
        },
        {
          count: Statistics
            ? Statistics.filter((i) => i.documentType === "User Requisition")
                .length
            : [],
          name: "User",
          avatarColor: "green accent-2",
          iconColor: "green darken-4",
          icon: "person",
        },
        {
          count: Statistics
            ? Statistics.filter(
                (i) => i.documentType === "Purchase Requisition"
              ).length
            : [],
          name: "Purchase",
          avatarColor: "teal accent-1",
          iconColor: "teal darken-4",
          icon: "shopping_bag",
        },
        {
          count: Statistics
            ? Statistics.filter((i) => i.documentType === "Store Requisition")
                .length
            : [],
          name: "Store",
          avatarColor: "orange accent-1",
          iconColor: "orange darken-4",
          icon: "store",
        },
      ];
    },

    Stats() {
      return this.$store.getters["Requisition/reqGetters"]("requisitions");
    },
    pieData() {
      const Statistics =
        this.$store.getters["Requisition/reqGetters"]("requisitions");
      return {
        labels: ["Released", "Open", "Pending"],
        data: [
          Statistics.filter((i) => i.status === "Released").length,
          Statistics.filter((i) => i.status === "Open").length,
          Statistics.filter((i) => i.status === "Pending Approval").length,
        ],
        title: "Requisition Status",
        backgroundColor: ["Released", "Open", "Pending"].map((el) =>
          helpers.stringToColour(el)
        ),
      };
    },
    barData() {
      return {
        labels: this.months,
        data: this.months.map((el, index) => {
          return this.Stats.filter(
            (item) => new Date(item.orderDate).getMonth() === index
          ).length;
        }),
        title: "Requisition Timeline",
        backgroundColor: this.months.map((el) => helpers.stringToColour(el)),
        amount: this.months.map((el, index) => {
          return this.Stats.filter(
            (item) => new Date(item.orderDate).getMonth() === index
          ).reduce(function (a, b) {
            return parseInt(a) + parseInt(b.amount);
          }, 0);
        }),
      };
    },
    Store() {
      const Statistics =
        this.$store.getters["Requisition/reqGetters"]("requisitions");
      return Statistics
        ? Statistics.filter((i) => i.documentType === "Store Requisition")
            .length
        : [];
    },
    User() {
      const Statistics =
        this.$store.getters["Requisition/reqGetters"]("requisitions");
      return Statistics
        ? Statistics.filter((i) => i.documentType === "User Requisition").length
        : [];
    },
    Purchase() {
      const Statistics =
        this.$store.getters["Requisition/reqGetters"]("requisitions");
      return Statistics
        ? Statistics.filter((i) => i.documentType === "Purchase Requisition")
            .length
        : [];
    },
    total() {
      const total = this.Store + this.User + this.Purchase;
      return total;
    },
  },
  methods: {},
};
</script>
