<template>
  <div>
    <v-menu
      ref="menu"
      v-model="startMenu"
      :close-on-content-click="true"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="startDateLocal"
          placeholder="Leave Start Date"
          label="Leave Start Date"
          persistent-placeholder
          clearable
          readonly
          dense
          v-bind="attrs"
          v-on="on"
          filled
          class="mt-1 leaveInputs"
          :rules="rules"
        >
          <template v-slot:prepend-inner>
            <v-icon color="primary"> mdi-calendar </v-icon>
          </template>
        </v-text-field>
      </template>
      <v-date-picker
        v-model="startDateLocal"
        no-title
        scrollable
        v-if="checkLeaveType !== undefined"
        :min="
          checkLeaveType.back_dated
            ? leavePeriod[0].current_period_start_date
            : today
        "
        :max="
          leavePeriod[0] !== undefined
            ? leavePeriod[0].current_period_end_date
            : null
        "
        @update:picker-date="pickerUpdate($event)"
        @input="startMenu = false"
      >
      </v-date-picker>
    </v-menu>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "StartDate",
  props: {
    startDate: String,
    leavePeriod: Array,
    rules: Array,
    displayDate: String,
    leaveTypes: Array,
    leave_type: String,
  },
  data: () => ({
    startMenu: false,
    today: new Date().toISOString().substr(0, 10),
  }),
  computed: {
    startDateLocal: {
      get() {
        return this.displayDate;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    checkLeaveType() {
      return this.leaveTypes.filter((item) => item.code === this.leave_type)[0];
    },
  },
  methods: {
    allowedDates(a) {
      return this.availableDates.includes(a);
    },
    pickerUpdate: function (val) {
      const totalDay = moment(val, "YYYY-MM").daysInMonth();
      const availableDates = [];
      const monthNow = moment().format("M");
      const monthSelected = moment(val).format("M");
      let day;
      if (monthNow === monthSelected) {
        day = moment().format("D");
      } else {
        day = 1;
      }
      for (let i = day; i <= totalDay; i++) {
        const date = moment()
          .month(val.split("-")[1] - 1)
          .date(i)
          .format("YYYY-MM-DD");
        if (moment(date).day() !== 0 && moment(date).day() !== 6) {
          availableDates.push(date);
        }
      }
      this.availableDates = availableDates;
      this.allowedDates();
    },
  },
};
</script>
