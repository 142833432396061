export default {
  trainingDocuments: "training",
  allTrainings: "training/get_trainings",
  trainingDocument: (no) => `training/${no}`,
  sendForApproval: "training/sendForApproval",
  cancelApproval: "training/cancelApproval",
  updateTraining: "training/updateTraining",
  deleteTraining: "training/deleteTraining",
  deleteLine: "training/line",
  deleteTrainee: "training/deleteTrainee",
  addTrainee: "training/addTrainee",
  trainee: "training/trainees",
  deleteAttachment: "training/deleteAttachment",
};
