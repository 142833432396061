import helpers from "@kinetics254/cassandra-base/utilities/helpers/helper";

export default {
  computed: {
    userData: function () {
      return this.$store.getters["auth/user"];
    },
  },
  methods: {
    getColor(status) {
      return helpers.getColor(status);
    },
    getDateFormat(date) {
      return helpers.getDateFormat(date);
    },
    sendApproval(val) {
      this.$confirm.show({
        title: "Send Approval Confirmation",
        text: "Are you sure you want to send this record for approval?",
        onConfirm: () => {
          this.$store.dispatch("allowance/sendApproval", val);
        },
      });
    },
    cancelApproval(val) {
      this.$confirm.show({
        title: "Cancel Approval Confirmation",
        text: "Are you sure you want to cancel this record`s approval request?",
        onConfirm: () => {
          this.$store.dispatch("allowance/sendApproval", val);
        },
      });
    },
    deleteAllowance(val) {
      // console.log(val)
      this.$confirm.show({
        title: "Cancel Approval Confirmation",
        text: "Are you sure you want to delete this record? This action is irreversible",
        onConfirm: () => {
          this.$store.dispatch("allowance/deleteAllowance", val);
        },
      });
    },
  },
};
