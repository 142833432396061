import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._t("stats",function(){return [_c(VLayout,{attrs:{"row":"","wrap":""}},_vm._l((_vm.data),function(item,i){return _c(VFlex,{key:i,staticClass:"lg5-custom",attrs:{"xs6":"","sm3":"","xl2":""}},[_c(VHover,{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{class:{ 'on-hover': hover },staticStyle:{"background-color":"#ffffff"},attrs:{"elevation":hover ? 10 : 1},on:{"click":function($event){return _vm.statsNavigator({ path: item.routePath, params: item.params })}}},[_c(VList,{attrs:{"dense":""}},[_c(VListItem,{attrs:{"dense":""}},[_c(VListItemContent,[_c('div',{staticClass:"text-lg-h4"},[_vm._v(_vm._s(item.count))]),_c(VListItemSubtitle,[_vm._v(_vm._s(item.name))])],1),_c(VListItemAction,[_c(VAvatar,{attrs:{"color":item.avatarColor,"size":"50"}},[_c(VIcon,{attrs:{"color":item.iconColor}},[_vm._v(_vm._s(item.icon))])],1)],1)],1)],1)],1)]}}],null,true)})],1)}),1)]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }