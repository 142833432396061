import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOtpInput } from 'vuetify/lib/components/VOtpInput';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VContainer,{staticClass:"pb-10"},[_c('div',{staticClass:"d-flex flex-column"},[_c('Logo',{staticClass:"align-self-center py-10",attrs:{"width":'10%',"height":'10%'}})],1),_c(VCard,{staticClass:"mx-auto loginCard",attrs:{"elevation":"10"}},[_c('div',{staticClass:"px-10"},[_c(VCardTitle,[_c(VAlert,{attrs:{"text":"","dense":"","color":"primary"}},[_c('div',{staticClass:"d-flex flex-row text-body-2"},[_c('div',{staticClass:"mr-2"},[_c(VIcon,[_vm._v("mdi-information-outline")])],1),_c('div',[_c('div',[_c('strong',[_vm._v("OTP Verification")])]),_c('div',[_vm._v(" Please enter the OTP sent to your email address to verify your account. ")])])])])],1),_c(VCardText,[_c(VForm,{ref:"otp"},[_c(VOtpInput,{attrs:{"length":"6","dense":"","type":"number","disabled":_vm.$store.getters['loader/loading']},on:{"finish":_vm.onFinish},model:{value:(_vm.otp),callback:function ($$v) {_vm.otp=$$v},expression:"otp"}})],1)],1),_c(VCardActions,[_c(VChip,{staticClass:"secondary lighten-4 secondary--text text--darken-4"},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-information-outline")]),_c('span',[_vm._v("OTP expires in "+_vm._s((_vm.mins + " min:" + _vm.seconds + " sec")))])],1),_c(VSpacer),_c(VBtn,{staticClass:"text-body-2 text-capitalize",attrs:{"text":"","color":"secondary"},on:{"click":_vm.resendOtp}},[_vm._v(" Resend OTP ")])],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }