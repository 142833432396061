import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c('TrainingBreadcrumbs'),_c(VCard,{staticClass:"mx-auto",attrs:{"elevation":"0"}},[_c(VCardTitle,[_c('div',{staticClass:"headline"},[_vm._v("Training Requests")]),_c(VSpacer),_c(VBtn,{staticClass:"primary",on:{"click":_vm.trainingCard}},[_c(VIcon,{staticClass:"mx-2"},[_vm._v("mdi-plus")]),_vm._v(" Add Training Request ")],1)],1),_c(VCardText,[_c(VTabs,{attrs:{"grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabsSlider,{attrs:{"color":"grey"}}),(!_vm.isSupervisor)?_c(VTab,[_vm._v("My Requests")]):_vm._e(),_c(VTab,[_vm._v(_vm._s(_vm.isSupervisor ? "All" : "Existing")+" Requests")]),_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,[_c(VContainer,[_c(VDataTable,{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":!_vm.isSupervisor ? _vm.trainings : _vm.allTrainings,"items-per-page":5,"footer-props":{
                  showFirstLastPage: true,
                  'items-per-page-text': 'Requests per page',
                }},scopedSlots:_vm._u([{key:"item.description",fn:function(ref){
                var item = ref.item;
return [(item.description)?_c('span',[_vm._v(" "+_vm._s(item.description.length > 30 ? ((item.description.slice(0, 40)) + "...") : item.description)+" ")]):_vm._e()]}},{key:"item.submittedBy",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(((item.submittedBy) + ": " + (item.submittedName)))+" ")]}},{key:"item.programmes",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.trainingNeedLines.length)+" ")]}},{key:"item.status",fn:function(ref){
                var item = ref.item;
return [_c(VChip,{attrs:{"small":"","label":"","color":_vm.getColor(item.status).color}},[_c('div',{staticStyle:{"color":"#fff"}},[_vm._v(_vm._s(item.status))])])]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [(item.status === 'Open')?_c('div',[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticClass:"mx-1",attrs:{"to":{
                            name: 'TrainingCard',
                            params: { code: _vm.encodeRoute(item.no) },
                          },"color":"teal lighten-4","label":"","small":""}},'v-chip',attrs,false),on),[_c(VIcon,{attrs:{"color":"teal darken-4","small":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("edit")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":"error lighten-4","label":"","small":""},on:{"click":function($event){return _vm.deleteTraining(item)}}},'v-chip',attrs,false),on),[_c(VIcon,{attrs:{"color":"error darken-4","small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("delete")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":"blue lighten-4","label":"","small":""},on:{"click":function($event){return _vm.sendForApproval(item)}}},'v-chip',attrs,false),on),[_c(VIcon,{attrs:{"color":"blue darken-4","small":""}},[_vm._v(" mdi-send ")])],1)]}}],null,true)},[_c('span',[_vm._v("send for approval")])])],1):_c('div',[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticClass:"mx-1",attrs:{"to":{
                            name: 'TrainingCard',
                            params: { code: _vm.encodeRoute(item.no) },
                          },"color":"blue lighten-4","label":"","small":""}},'v-chip',attrs,false),on),[_c(VIcon,{attrs:{"color":"blue darken-4","small":""}},[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("view")])]),(item.status === 'Pending Approval')?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":"orange lighten-4","label":"","small":""},on:{"click":function($event){return _vm.cancelApprovalRequest(item)}}},'v-chip',attrs,false),on),[_c(VIcon,{attrs:{"color":"orange darken-4","small":""}},[_vm._v(" cancel_schedule_send ")])],1)]}}],null,true)},[_c('span',[_vm._v("cancel approval request")])]):_vm._e()],1)]}}])})],1)],1),(!_vm.isSupervisor)?_c(VTabItem,[_c(VContainer,[_c(VDataTable,{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.allTrainings,"items-per-page":5,"dense":"","footer-props":{
                  showFirstLastPage: true,
                  'items-per-page-text': 'Requests per page',
                }},scopedSlots:_vm._u([{key:"item.description",fn:function(ref){
                var item = ref.item;
return [(item.description)?_c('span',[_vm._v(" "+_vm._s(item.description.length > 30 ? ((item.description.slice(0, 40)) + "...") : item.description)+" ")]):_vm._e()]}},{key:"item.submittedBy",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(((item.submittedBy) + ": " + (item.submittedName)))+" ")]}},{key:"item.programmes",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.trainingNeedLines.length)+" ")]}},{key:"item.status",fn:function(ref){
                var item = ref.item;
return [_c(VChip,{attrs:{"small":"","label":"","color":_vm.getColor(item.status).color}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[(item.status !== 'Open')?_c(VChip,{staticClass:"mr-2",attrs:{"color":"teal lighten-4","small":"","label":""},on:{"click":function($event){_vm.$router.push({
                          name: 'TrainingCard',
                          params: { code: _vm.encodeRoute(item.no) },
                        })}}},[_c('span',{staticClass:"text--teal-darken-2 text-uppercase"},[_vm._v(" view ")])]):_vm._e(),(item.status === 'Open')?_c(VChip,{staticClass:"mr-2",attrs:{"color":"blue lighten-4","small":"","label":""},on:{"click":function($event){_vm.$router.push({
                          name: 'TrainingCard',
                          params: { code: _vm.encodeRoute(item.no) },
                        })}}},[_c('span',{staticClass:"blue--text-darken-2 text-uppercase"},[_vm._v(" edit ")])]):_vm._e()],1)]}}],null,false,1728007784)})],1)],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }