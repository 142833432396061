import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VCard,{staticClass:"my-5 elevation-0",attrs:{"height":"100%"}},[_c(VCardTitle,[_c(VBtn,{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.$router.back()}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-arrow-left ")]),_vm._v(" Back ")],1),_c(VSpacer),_c(VBtn,{attrs:{"small":"","color":"secondary"},on:{"click":function($event){return _vm.downloadLink(_vm.document)}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-download ")]),_vm._v(" Download ")],1)],1),_c(VDivider),_c(VRow,[_c(VCol,{attrs:{"cols":"8","offset":"2"}},[_c(VCardText,{staticClass:"elevation-1 my-1"},[_c('pdf',{staticStyle:{"width":"100%"},attrs:{"src":_vm.document}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }