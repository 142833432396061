import ReqLinks from "./ReqLinks";
import ReqRoutes from "./ReqRoutes";
import ReqStore from "./ReqStore";

export default {
  install(Vue, options) {
    /* register store and routes */
    if (options.router) options.router.addRoute(...ReqRoutes);
    if (options.store) options.store.registerModule("Requisition", ReqStore);

    options.store.commit("Dashboard/ADD_LINK", ReqLinks);
  },
};
