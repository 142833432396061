<template>
  <div>
    <!-- <v-tooltip bottom v-if="leaveEmployee.email !== undefined"> -->
    <v-tooltip bottom v-if="leaveEmployee ? leaveEmployee.email : ''">
      <template v-slot:activator="{ on, attrs }">
        <v-avatar color="blue lighten-4" size="40" v-bind="attrs" v-on="on">
          {{
            (leaveEmployee.email[0] + leaveEmployee.email[1])
              .toString()
              .toUpperCase()
          }}
        </v-avatar>
      </template>
      <span>{{ leaveEmployee ? leaveEmployee.email : "N/A" }}</span>
    </v-tooltip>
  </div>
</template>
<script>
export default {
  name: "TopBar",
  props: { leaveEmployee: Object },
  computed: {},
  mounted() {},
};
</script>
