import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VImg } from 'vuetify/lib/components/VImg';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VOverlay,{attrs:{"absolute":"","color":"#EBF5FB","opacity":"1.0"}},[_c('div',[_c('div',{staticClass:"d-flex flex-column my-4"},[_c('div',{staticClass:"align-self-center"},[_c('div',{staticClass:"text--primary text-body-1"},[_vm._v("Switching Companies")])])]),_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"align-self-start mx-3"},[_c(VBadge,{attrs:{"content":_vm.company.displayName[0],"bottom":"","overlap":"","offset-x":"20","offset-y":"20"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VAvatar,_vm._g(_vm._b({attrs:{"color":"blue lighten-4","size":"90"}},'v-avatar',attrs,false),on),[_c(VImg,{attrs:{"src":("data:application/jpg;base64," + (_vm.company.base64Logo)),"contain":""}})],1)]}}])},[_c('span',{staticClass:"text--primary text-body-1"},[_vm._v(" "+_vm._s(_vm.company.displayName)+" ")])])],1)],1),_c('div',{staticClass:"align-self-center"},[_c('CirclesToRhombusesSpinner',{attrs:{"animation-duration":1200,"circles-num":4,"circle-size":15,"color":"#087F23"}})],1),_c('div',{staticClass:"align-self-end mx-4"},[_c(VBadge,{attrs:{"content":_vm.selectedCompany.name[0],"bottom":"","overlap":"","offset-x":"20","offset-y":"20"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VAvatar,_vm._g(_vm._b({attrs:{"color":"blue darken-4","size":"90"}},'v-avatar',attrs,false),on),[_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(_vm.selectedCompany.name[0] + _vm.selectedCompany.name[1]))])])]}}])},[_c('div',{staticClass:"text--primary text-body-1"},[_vm._v(" "+_vm._s(_vm.selectedCompany.displayName)+" ")])])],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }