import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VFooter,{attrs:{"app":"","inset":true,"elevation":"12","color":_vm.color}},[_c(VMenu,{attrs:{"top":"","close-on-content-click":"","disabled":!_vm.company.switch_company},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
var value = ref.value;
return [_c(VBtn,_vm._g({staticClass:"text-capitalize",attrs:{"color":"primary","text":"","link":"","v-bind":attrs}},on),[_vm._v(" "+_vm._s(!_vm.company.switch_company ? ("Default Company: " + (_vm.company.displayName ? _vm.company.displayName : "")) : "Select Default Company")+" "),(_vm.company.switch_company)?_c(VIcon,{staticClass:"mx-2"},[_vm._v(_vm._s(!value ? "keyboard_arrow_up" : "keyboard_arrow_down"))]):_vm._e()],1)]}}])},[_c(VList,{attrs:{"dense":""}},[_c(VListItemGroup,_vm._l((_vm.companies),function(item,i){return _c(VListItem,{key:i,on:{"click":function($event){return _vm.setCompany(item)}}},[_c(VListItemTitle,[_vm._v(_vm._s(item.name))]),(_vm.user.default_company === item.id)?_c(VChip,{attrs:{"x-small":"","color":"green lighten-1","label":""}},[_vm._v("default")]):_vm._e()],1)}),1)],1)],1),_c(VSpacer),_c('span',{staticStyle:{"color":"#000000"}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.appName))+" © "+_vm._s(new Date().getFullYear())+" ")]),_c(VSpacer),_c('a',{staticClass:"text-decoration-none primary--text",attrs:{"href":"https://kinetics.co.ke/","target":"_blank"}},[_vm._v(" Kinetics Technology LTD ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }