import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pb-3"},[_c('div',{staticStyle:{"text-align":"center","display":"block"}},[_c('Logo',{staticClass:"py-3",attrs:{"width":'10%',"height":'10%'}})],1),_c(VCard,{staticClass:"mx-auto loginCard",attrs:{"elevation":"10"}},[_c('div',{staticClass:"px-10"},[_c(VCardTitle,{staticClass:"text-center"},[_c(VSpacer),_vm._v(" Set Password "),_c(VSpacer)],1),_c(VCardText,[_c(VAlert,{staticClass:"my-2",attrs:{"type":"info","dense":"","outlined":""}},[_vm._v(" Your almost there, enter and confirm your password to login. ")]),_c(VForm,{ref:"setPasswordForm",on:{"submit":function($event){$event.preventDefault();return _vm.setPassword.apply(null, arguments)}}},[_c(VTextField,{attrs:{"outlined":"","dense":"","append-icon":_vm.showPassword ? 'visibility' : 'visibility_off',"rules":[_vm.rules.password.required, _vm.rules.password.min],"type":_vm.showPassword ? 'text' : 'password',"name":"password","label":"Password"},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}}),_c('div',{staticClass:"py-2",style:({
              color: _vm.strengthColor,
              marginTop: _vm.strengthValue.length === 0 ? '-5%' : '-10%',
              textAlign: 'right',
            })},[_vm._v(" "+_vm._s(_vm.strengthValue)+" ")]),_c('password',{staticStyle:{"margin-top":"-4%"},attrs:{"strength-meter-only":true},on:{"score":_vm.showScore},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}}),_c(VTextField,{attrs:{"outlined":"","dense":"","append-icon":_vm.showPassword ? 'visibility' : 'visibility_off',"rules":[_vm.rules.password.required, _vm.rules.password.min],"type":_vm.showPassword ? 'text' : 'password',"name":"confirmPassword","label":"Confirm password"},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.formData.confirmPassword),callback:function ($$v) {_vm.$set(_vm.formData, "confirmPassword", $$v)},expression:"formData.confirmPassword"}}),_c(VBtn,{staticClass:"mt-2",attrs:{"color":"primary","block":"","type":"submit"}},[(_vm.setLoading)?_c('div',[_c(VProgressCircular,{attrs:{"size":30,"width":3,"color":"white","indeterminate":""}})],1):_c('div',[_vm._v("Set Password")])])],1)],1)],1)]),_c('auth-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }